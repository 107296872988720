/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
attachment-info {
  display: block;
}
attachment-info .file-container {
  padding-bottom: 0;
}
attachment-info .file-container .file-header {
  padding: 0 8px;
  color: #757575;
  font-size: 16px;
  font-weight: normal;
}
attachment-info .file-container .file-header md-icon {
  margin-right: 0.5em;
  width: 0.9em;
  height: 0.9em;
  font-size: 22px;
}
attachment-info .file-container .file-content {
  padding: 0 8px;
  margin: 0;
  display: block;
  color: #757575;
  line-height: 20px;
}