/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
app-switcher {
  display: block;
  padding: 0 12px;
  line-height: 64px;
}
app-switcher .dropdown-toggle {
  display: inline-block;
}
app-switcher .dropdown-toggle img {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  border-radius: 4px;
}
app-switcher .dropdown-toggle md-icon {
  margin-left: 4px;
}
app-switcher .dropdown {
  position: absolute;
  background-color: #D6DBE2;
  margin-left: -12px;
  width: 249px;
  z-index: 6;
}
app-switcher .dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
app-switcher .dropdown ul li {
  padding: 6px 0;
  line-height: 50px;
  border-left: 3px solid transparent;
  animation: ANIM-FADE-IN 500ms;
}
app-switcher .dropdown ul li img {
  vertical-align: middle;
  margin-right: 12px;
  margin-left: 8px;
  border-radius: 4px;
}
app-switcher .dropdown ul li.active {
  border-left-color: #FF3600;
  color: #111;
  cursor: default;
}
app-switcher .dropdown ul li:focus {
  outline: none;
}
app-switcher .dropdown ul li:hover {
  background-color: #AAAEB3;
}