/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
div.auth main div.signup-invite password-component md-input-container {
  margin-left: 0px !important;
}
div.auth main div.signup show-password, div.auth main div.signup-create show-password, div.auth main div.invite show-password {
  margin-top: -16px;
}
div.auth main div.signup div.secondary-links, div.auth main div.signup-create div.secondary-links, div.auth main div.invite div.secondary-links, div.auth main div.signup-invite div.secondary-links {
  justify-content: flex-end;
}
div.auth main div.signup form, div.auth main div.signup div.summary, div.auth main div.signup-create form, div.auth main div.signup-create div.summary, div.auth main div.invite form, div.auth main div.invite div.summary, div.auth main div.signup-invite form, div.auth main div.signup-invite div.summary {
  padding: 1em;
}
@media (max-width: 599px) {
  div.auth main div.signup form, div.auth main div.signup div.summary, div.auth main div.signup-create form, div.auth main div.signup-create div.summary, div.auth main div.invite form, div.auth main div.invite div.summary, div.auth main div.signup-invite form, div.auth main div.signup-invite div.summary {
    padding: 0;
  }
}
div.auth main div.signup form h2, div.auth main div.signup div.summary h2, div.auth main div.signup-create form h2, div.auth main div.signup-create div.summary h2, div.auth main div.invite form h2, div.auth main div.invite div.summary h2, div.auth main div.signup-invite form h2, div.auth main div.signup-invite div.summary h2 {
  margin-bottom: 18px;
}
div.auth main div.signup form div.input-with-icon, div.auth main div.signup div.summary div.input-with-icon, div.auth main div.signup-create form div.input-with-icon, div.auth main div.signup-create div.summary div.input-with-icon, div.auth main div.invite form div.input-with-icon, div.auth main div.invite div.summary div.input-with-icon, div.auth main div.signup-invite form div.input-with-icon, div.auth main div.signup-invite div.summary div.input-with-icon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
div.auth main div.signup form div.input-with-icon md-icon, div.auth main div.signup div.summary div.input-with-icon md-icon, div.auth main div.signup-create form div.input-with-icon md-icon, div.auth main div.signup-create div.summary div.input-with-icon md-icon, div.auth main div.invite form div.input-with-icon md-icon, div.auth main div.invite div.summary div.input-with-icon md-icon, div.auth main div.signup-invite form div.input-with-icon md-icon, div.auth main div.signup-invite div.summary div.input-with-icon md-icon {
  margin: 16px 0 0 0;
}
div.auth main div.signup form div.input-with-icon md-input-container, div.auth main div.signup div.summary div.input-with-icon md-input-container, div.auth main div.signup-create form div.input-with-icon md-input-container, div.auth main div.signup-create div.summary div.input-with-icon md-input-container, div.auth main div.invite form div.input-with-icon md-input-container, div.auth main div.invite div.summary div.input-with-icon md-input-container, div.auth main div.signup-invite form div.input-with-icon md-input-container, div.auth main div.signup-invite div.summary div.input-with-icon md-input-container {
  width: calc(100% - 32px);
  margin: 9px 0;
}
div.auth main div.signup form div.terms, div.auth main div.signup div.summary div.terms, div.auth main div.signup-create form div.terms, div.auth main div.signup-create div.summary div.terms, div.auth main div.invite form div.terms, div.auth main div.invite div.summary div.terms, div.auth main div.signup-invite form div.terms, div.auth main div.signup-invite div.summary div.terms {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
div.auth main div.signup form div.terms md-checkbox, div.auth main div.signup div.summary div.terms md-checkbox, div.auth main div.signup-create form div.terms md-checkbox, div.auth main div.signup-create div.summary div.terms md-checkbox, div.auth main div.invite form div.terms md-checkbox, div.auth main div.invite div.summary div.terms md-checkbox, div.auth main div.signup-invite form div.terms md-checkbox, div.auth main div.signup-invite div.summary div.terms md-checkbox {
  margin-top: 10px;
  margin-left: 3px;
}
div.auth main div.signup form div.terms span.label, div.auth main div.signup div.summary div.terms span.label, div.auth main div.signup-create form div.terms span.label, div.auth main div.signup-create div.summary div.terms span.label, div.auth main div.invite form div.terms span.label, div.auth main div.invite div.summary div.terms span.label, div.auth main div.signup-invite form div.terms span.label, div.auth main div.signup-invite div.summary div.terms span.label {
  width: calc(100% - 32px);
  margin: 9px 0;
  text-align: left;
  line-height: 1.4;
}
div.auth main div.signup form div.actions, div.auth main div.signup div.summary div.actions, div.auth main div.signup-create form div.actions, div.auth main div.signup-create div.summary div.actions, div.auth main div.invite form div.actions, div.auth main div.invite div.summary div.actions, div.auth main div.signup-invite form div.actions, div.auth main div.signup-invite div.summary div.actions {
  padding: 0;
}
div.auth main div.signup form div.actions .md-button, div.auth main div.signup div.summary div.actions .md-button, div.auth main div.signup-create form div.actions .md-button, div.auth main div.signup-create div.summary div.actions .md-button, div.auth main div.invite form div.actions .md-button, div.auth main div.invite div.summary div.actions .md-button, div.auth main div.signup-invite form div.actions .md-button, div.auth main div.signup-invite div.summary div.actions .md-button {
  margin: 0;
}
div.auth main div.signup form div.secondary-links, div.auth main div.signup div.summary div.secondary-links, div.auth main div.signup-create form div.secondary-links, div.auth main div.signup-create div.summary div.secondary-links, div.auth main div.invite form div.secondary-links, div.auth main div.invite div.summary div.secondary-links, div.auth main div.signup-invite form div.secondary-links, div.auth main div.signup-invite div.summary div.secondary-links {
  justify-content: flex-end;
  top: 41px;
}
div.auth main div.signup form div.secondary-links .invite, div.auth main div.signup div.summary div.secondary-links .invite, div.auth main div.signup-create form div.secondary-links .invite, div.auth main div.signup-create div.summary div.secondary-links .invite, div.auth main div.invite form div.secondary-links .invite, div.auth main div.invite div.summary div.secondary-links .invite, div.auth main div.signup-invite form div.secondary-links .invite, div.auth main div.signup-invite div.summary div.secondary-links .invite {
  width: 100%;
  margin-left: -5px;
}
div.auth main div.signup form password-component > md-input-container, div.auth main div.signup div.summary password-component > md-input-container, div.auth main div.signup-create form password-component > md-input-container, div.auth main div.signup-create div.summary password-component > md-input-container, div.auth main div.invite form password-component > md-input-container, div.auth main div.invite div.summary password-component > md-input-container, div.auth main div.signup-invite form password-component > md-input-container, div.auth main div.signup-invite div.summary password-component > md-input-container {
  margin: 8px auto;
}
div.auth main div.signup div.summary p, div.auth main div.signup-create div.summary p, div.auth main div.invite div.summary p, div.auth main div.signup-invite div.summary p {
  text-align: left;
}
div.auth main div.signup div.summary p.intro, div.auth main div.signup-create div.summary p.intro, div.auth main div.invite div.summary p.intro, div.auth main div.signup-invite div.summary p.intro {
  margin-bottom: initial;
}
div.auth main div.signup div.summary .subscription-info, div.auth main div.signup-create div.summary .subscription-info, div.auth main div.invite div.summary .subscription-info, div.auth main div.signup-invite div.summary .subscription-info {
  margin-bottom: 1.5em;
}
div.auth main div.signup div.summary .subscription-info > div, div.auth main div.signup-create div.summary .subscription-info > div, div.auth main div.invite div.summary .subscription-info > div, div.auth main div.signup-invite div.summary .subscription-info > div {
  margin-bottom: 0.5em;
}
div.auth main div.signup div.summary .subscription-info strong, div.auth main div.signup-create div.summary .subscription-info strong, div.auth main div.invite div.summary .subscription-info strong, div.auth main div.signup-invite div.summary .subscription-info strong {
  display: inline-block;
  font-weight: 400;
}
div.auth main div.signup div.summary .subscription-info strong:after, div.auth main div.signup-create div.summary .subscription-info strong:after, div.auth main div.invite div.summary .subscription-info strong:after, div.auth main div.signup-invite div.summary .subscription-info strong:after {
  content: ":";
  display: inline-block;
  margin-right: 10px;
}
div.auth main div.signup div.summary div.actions, div.auth main div.signup-create div.summary div.actions, div.auth main div.invite div.summary div.actions, div.auth main div.signup-invite div.summary div.actions {
  margin-top: 1em;
}
div.auth main div.signup blockquote, div.auth main div.signup-create blockquote, div.auth main div.invite blockquote, div.auth main div.signup-invite blockquote {
  margin-left: 5px !important;
  padding-left: 1em !important;
}
div.auth main div.signup.with-captcha form div.input-with-submit md-input-container {
  width: 100%;
  padding-left: 0;
}
div.auth main div.signup.with-captcha form div.input-with-submit md-input-container label {
  margin-left: -38px;
}
div.auth main div.signup.with-captcha form div.input-with-submit md-input-container md-icon {
  margin-left: 11px;
}
div.auth main div.signup.with-captcha form div.input-with-submit md-input-container input {
  padding-left: 46px;
}
div.auth main div.signup.with-captcha form div.input-with-submit .md-button {
  width: 100%;
  margin-left: 0;
  margin-top: 0;
  align-self: stretch;
}
div.auth main div.signup.with-captcha form div.input-with-submit div.captcha-widget {
  width: 100%;
  min-width: auto;
  max-width: 100%;
  margin-bottom: 40px;
}
div.auth main div.signup.with-captcha form .primary-links, div.auth main div.signup.with-captcha form .secondary-links {
  justify-content: center;
}
div.auth main div.signup.with-captcha form .primary-links p a.link, div.auth main div.signup.with-captcha form .secondary-links p a.link {
  text-decoration: underline;
  font-weight: bold;
}
@media (min-width: 599px) {
  div.auth main div.signup.with-captcha form .primary-links p, div.auth main div.signup.with-captcha form .secondary-links p {
    margin-bottom: 0;
  }
}
div.auth main div.signup.with-captcha form .primary-links {
  margin: 18px 0 0;
}