language-select {
  display: block;
}
language-select label md-icon.material-icons {
  display: inline-block;
  font-size: 20px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
language-select md-select {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
  margin-bottom: 6px;
}
language-select .md-select-value *:first-child {
  height: auto;
}
language-select .md-select-value .md-select-icon:after {
  top: 2px;
}