/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
conversation-error {
  display: block;
  width: 100%;
}
conversation-error md-card {
  max-width: 540px;
  margin: 1em auto;
  padding: 24px;
}
conversation-error md-card img {
  text-align: center;
}
conversation-error md-card h2.title {
  text-align: center;
}
conversation-error md-card p.subtitle {
  font-weight: 500;
}