/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
draft-recipients {
  display: flex;
}
draft-recipients .no-display {
  display: none;
}
draft-recipients div.recipients-row {
  width: 100%;
}
draft-recipients div.recipients-row label.recipient-label {
  font-weight: bold;
  min-width: 32px;
  margin-top: 16px;
  padding-left: 4px;
}
draft-recipients div.recipients-row button#bcc-toggle {
  min-width: fit-content;
}
draft-recipients div.recipients-row md-chips.no-button md-chips-wrap {
  width: calc(100% + 28px);
}
draft-recipients div.recipients-row md-chips.chips-only md-chips-wrap {
  width: 100%;
  margin-left: 0px;
  padding-left: 0px;
}
draft-recipients div.recipients-row md-chips.label-only md-chips-wrap {
  width: calc(100% + 28px);
}
draft-recipients div.recipients-row md-chips md-chips-wrap {
  width: calc(100% + 88px);
  margin-left: -30px;
  padding-left: 40px;
}
draft-recipients div.recipients-row md-chips md-chip.violation-error .md-chip-remove-container md-icon {
  color: #111;
}
draft-recipients div.recipients-row md-chips md-chip .md-chip-remove-container {
  height: 32px;
}
draft-recipients div.recipients-row md-chips md-chip.violation-okay {
  background-color: #f5f5f5;
}
draft-recipients div.recipients-row md-chips md-chip.violation-okay md-icon {
  color: #96999e;
}
draft-recipients div.recipients-row md-chips md-chip.violation-okay avatar > div.picture {
  background-color: gainsboro;
}
draft-recipients div.recipients-row md-chips md-chip.violation-warning {
  background-color: #fff7ed;
}
draft-recipients div.recipients-row md-chips md-chip.violation-warning avatar > div.picture {
  background-color: #ffe0ba;
}
draft-recipients div.recipients-row md-chips md-chip.violation-warning:hover, draft-recipients div.recipients-row md-chips md-chip.violation-warning.md-focused {
  background-color: #ffecd4;
}
draft-recipients div.recipients-row md-chips md-chip.violation-warning:hover md-icon.validation-icon, draft-recipients div.recipients-row md-chips md-chip.violation-warning.md-focused md-icon.validation-icon {
  color: #fb743d;
}
draft-recipients div.recipients-row md-chips md-chip.violation-okay:hover, draft-recipients div.recipients-row md-chips md-chip.violation-okay.md-focused {
  background-color: #e8e8e8;
}
draft-recipients div.recipients-row md-chips .md-chip-content {
  height: 32px !important;
}
draft-recipients div.recipients-row md-chips .md-chip-input-container input {
  width: auto;
}

.md-virtual-repeat-container {
  width: 350px !important;
}

.md-autocomplete-suggestions-container {
  border-radius: 3px;
}

ul.md-contact-chips-suggestions {
  overflow: hidden;
}
@media (max-width: 600px) {
  ul.md-contact-chips-suggestions li:hover {
    background: none;
  }
}
ul.md-contact-chips-suggestions li {
  padding: 0 8px;
  height: 80px;
  line-height: 32px;
  border-bottom: 1px solid rgba(158, 158, 158, 0.2);
}
ul.md-contact-chips-suggestions li .contact-suggestion {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
ul.md-contact-chips-suggestions li .contact-suggestion .contact-details {
  width: 80%;
  margin: auto 8px;
}
@media screen and (min-width: 0\0 ) {
  ul.md-contact-chips-suggestions li .contact-suggestion .contact-details {
    margin-left: 8px;
  }
  ul.md-contact-chips-suggestions li .contact-suggestion .contact-details.single-line {
    height: 30px;
  }
  ul.md-contact-chips-suggestions li .contact-suggestion .contact-details.double-line {
    height: 60px;
  }
}
ul.md-contact-chips-suggestions li .contact-suggestion .contact-details .contact-name {
  height: 30px;
}
ul.md-contact-chips-suggestions li .contact-suggestion .contact-details .contact-email {
  height: 30px;
  float: left;
}
ul.md-contact-chips-suggestions li .contact-suggestion div.md-contact-name, ul.md-contact-chips-suggestions li .contact-suggestion div.md-contact-email {
  display: block;
  float: left;
}
ul.md-contact-chips-suggestions li .contact-suggestion div.md-contact-email {
  padding-left: 8px;
  max-width: 200px;
  color: #757575 !important;
}
ul.md-contact-chips-suggestions li .contact-suggestion span.highlight {
  font-weight: 500;
  padding: 2px;
  border-radius: 4px;
  color: #757575 !important;
  background-color: #FFF7ED;
}