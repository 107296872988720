/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
body.conversations .column-content .main .conversations,
body.conversations .column-content .main .drafts,
body.drafts .column-content .main .conversations,
body.drafts .column-content .main .drafts {
  height: 100%;
}
body.conversations .recipients img,
body.conversations .md-user-avatar,
body.drafts .recipients img,
body.drafts .md-user-avatar {
  background-color: #f5f5f5;
  color: #111;
}
body.conversations div.conversations-list,
body.conversations md-content.drafts-list,
body.drafts div.conversations-list,
body.drafts md-content.drafts-list {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #FFFFFF;
  height: calc(100% - 64px);
  transform: translate3d(0, 0, 0);
  -webkit-overflow-scrolling: touch;
}
body.conversations div.conversations-list .div-list .md-title,
body.conversations md-content.drafts-list .div-list .md-title,
body.drafts div.conversations-list .div-list .md-title,
body.drafts md-content.drafts-list .div-list .md-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
body.conversations div.conversations-list .div-list .div-list-item,
body.conversations md-content.drafts-list .div-list .div-list-item,
body.drafts div.conversations-list .div-list .div-list-item,
body.drafts md-content.drafts-list .div-list .div-list-item {
  padding: 10px 6px 0 16px;
  border-bottom: 1px solid #E0E0E0;
  position: relative;
  min-height: 75px !important;
}
body.conversations div.conversations-list .div-list .div-list-item.active,
body.conversations md-content.drafts-list .div-list .div-list-item.active,
body.drafts div.conversations-list .div-list .div-list-item.active,
body.drafts md-content.drafts-list .div-list .div-list-item.active {
  background-color: #fbf3ef;
  outline: none;
}
body.conversations div.conversations-list .div-list .div-list-item.selected,
body.conversations md-content.drafts-list .div-list .div-list-item.selected,
body.drafts div.conversations-list .div-list .div-list-item.selected,
body.drafts md-content.drafts-list .div-list .div-list-item.selected {
  background-color: #E0E0E0;
}
body.conversations div.conversations-list .div-list .div-list-item:hover, body.conversations div.conversations-list .div-list .div-list-item:active,
body.conversations md-content.drafts-list .div-list .div-list-item:hover,
body.conversations md-content.drafts-list .div-list .div-list-item:active,
body.drafts div.conversations-list .div-list .div-list-item:hover,
body.drafts div.conversations-list .div-list .div-list-item:active,
body.drafts md-content.drafts-list .div-list .div-list-item:hover,
body.drafts md-content.drafts-list .div-list .div-list-item:active {
  background-color: #FAFAFA;
  outline: 0;
}
body.conversations div.conversations-list .div-list .div-list-item .md-button,
body.conversations md-content.drafts-list .div-list .div-list-item .md-button,
body.drafts div.conversations-list .div-list .div-list-item .md-button,
body.drafts md-content.drafts-list .div-list .div-list-item .md-button {
  line-height: 15px;
  min-height: 75px !important;
}
body.conversations div.conversations-list .div-list .div-list-item md-icon,
body.conversations md-content.drafts-list .div-list .div-list-item md-icon,
body.drafts div.conversations-list .div-list .div-list-item md-icon,
body.drafts md-content.drafts-list .div-list .div-list-item md-icon {
  display: inline-block;
  margin-left: 0;
  margin-right: 10px;
}
body.conversations div.conversations-list .div-list .div-list-item .participants,
body.conversations md-content.drafts-list .div-list .div-list-item .participants,
body.drafts div.conversations-list .div-list .div-list-item .participants,
body.drafts md-content.drafts-list .div-list .div-list-item .participants {
  margin-left: -5px;
  margin-right: 10px;
  width: 45px;
  flex-wrap: wrap;
}
body.conversations div.conversations-list .div-list .div-list-item .participants img,
body.conversations md-content.drafts-list .div-list .div-list-item .participants img,
body.drafts div.conversations-list .div-list .div-list-item .participants img,
body.drafts md-content.drafts-list .div-list .div-list-item .participants img {
  border-radius: 50%;
  background-color: #757575;
}
body.conversations div.conversations-list .div-list .div-list-item .participants.participant-multiple div.extra-participants,
body.conversations md-content.drafts-list .div-list .div-list-item .participants.participant-multiple div.extra-participants,
body.drafts div.conversations-list .div-list .div-list-item .participants.participant-multiple div.extra-participants,
body.drafts md-content.drafts-list .div-list .div-list-item .participants.participant-multiple div.extra-participants {
  height: 20px;
  width: 20px;
  margin: 1px;
  border-radius: 50%;
  background: #f5f5f5;
  text-align: center;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body.conversations div.conversations-list .div-list .div-list-item .participants.participant-multiple div.extra-participants span,
body.conversations md-content.drafts-list .div-list .div-list-item .participants.participant-multiple div.extra-participants span,
body.drafts div.conversations-list .div-list .div-list-item .participants.participant-multiple div.extra-participants span,
body.drafts md-content.drafts-list .div-list .div-list-item .participants.participant-multiple div.extra-participants span {
  font-size: 12px;
  font-weight: bold;
}
body.conversations div.conversations-list .div-list .div-list-item .div-list-item-text,
body.conversations md-content.drafts-list .div-list .div-list-item .div-list-item-text,
body.drafts div.conversations-list .div-list .div-list-item .div-list-item-text,
body.drafts md-content.drafts-list .div-list .div-list-item .div-list-item-text {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
  position: relative;
  top: -5px;
}
body.conversations div.conversations-list .div-list .div-list-item .div-list-item-text:focus,
body.conversations md-content.drafts-list .div-list .div-list-item .div-list-item-text:focus,
body.drafts div.conversations-list .div-list .div-list-item .div-list-item-text:focus,
body.drafts md-content.drafts-list .div-list .div-list-item .div-list-item-text:focus {
  outline-width: 0;
}
body.conversations div.conversations-list .div-list .div-list-item .extend,
body.conversations md-content.drafts-list .div-list .div-list-item .extend,
body.drafts div.conversations-list .div-list .div-list-item .extend,
body.drafts md-content.drafts-list .div-list .div-list-item .extend {
  flex-basis: 100%;
}
@media screen and (min-width: 0\0 ) {
  body.conversations div.conversations-list .div-list .div-list-item div.empty-list-item,
  body.conversations md-content.drafts-list .div-list .div-list-item div.empty-list-item,
  body.drafts div.conversations-list .div-list .div-list-item div.empty-list-item,
  body.drafts md-content.drafts-list .div-list .div-list-item div.empty-list-item {
    top: 30px;
  }
}
body.conversations div.conversations-list .div-list .div-list-item div.first-line,
body.conversations md-content.drafts-list .div-list .div-list-item div.first-line,
body.drafts div.conversations-list .div-list .div-list-item div.first-line,
body.drafts md-content.drafts-list .div-list .div-list-item div.first-line {
  color: #1F1F1F;
  font-size: 14px;
  line-height: 1.5em;
  position: relative;
  top: 5px;
  padding-right: 40px;
}
body.conversations div.conversations-list .div-list .div-list-item div.first-line .truncate,
body.conversations md-content.drafts-list .div-list .div-list-item div.first-line .truncate,
body.drafts div.conversations-list .div-list .div-list-item div.first-line .truncate,
body.drafts md-content.drafts-list .div-list .div-list-item div.first-line .truncate {
  width: 85%;
}
body.conversations div.conversations-list .div-list .div-list-item div.second-line,
body.conversations md-content.drafts-list .div-list .div-list-item div.second-line,
body.drafts div.conversations-list .div-list .div-list-item div.second-line,
body.drafts md-content.drafts-list .div-list .div-list-item div.second-line {
  padding-right: 40px;
}
body.conversations div.conversations-list .div-list .div-list-item div.second-line,
body.conversations div.conversations-list .div-list .div-list-item div.third-line,
body.conversations md-content.drafts-list .div-list .div-list-item div.second-line,
body.conversations md-content.drafts-list .div-list .div-list-item div.third-line,
body.drafts div.conversations-list .div-list .div-list-item div.second-line,
body.drafts div.conversations-list .div-list .div-list-item div.third-line,
body.drafts md-content.drafts-list .div-list .div-list-item div.second-line,
body.drafts md-content.drafts-list .div-list .div-list-item div.third-line {
  color: #757575;
  font-size: 12px;
}
body.conversations div.conversations-list .div-list .div-list-item div.third-line,
body.conversations md-content.drafts-list .div-list .div-list-item div.third-line,
body.drafts div.conversations-list .div-list .div-list-item div.third-line,
body.drafts md-content.drafts-list .div-list .div-list-item div.third-line {
  color: #757575;
  font-size: 12px;
}
body.conversations div.conversations-list .div-list .div-list-item div.third-line span,
body.conversations md-content.drafts-list .div-list .div-list-item div.third-line span,
body.drafts div.conversations-list .div-list .div-list-item div.third-line span,
body.drafts md-content.drafts-list .div-list .div-list-item div.third-line span {
  float: left;
}
body.conversations div.conversations-list .div-list .div-list-item div.messageType,
body.conversations md-content.drafts-list .div-list .div-list-item div.messageType,
body.drafts div.conversations-list .div-list .div-list-item div.messageType,
body.drafts md-content.drafts-list .div-list .div-list-item div.messageType {
  text-align: right;
}
body.conversations div.conversations-list .div-list .div-list-item div.messageType .date,
body.conversations md-content.drafts-list .div-list .div-list-item div.messageType .date,
body.drafts div.conversations-list .div-list .div-list-item div.messageType .date,
body.drafts md-content.drafts-list .div-list .div-list-item div.messageType .date {
  position: absolute;
  top: 19px;
  right: 10px;
  color: #757575;
  font-size: 12px;
}
body.conversations div.conversations-list .div-list .div-list-item div.messageType .icons,
body.conversations md-content.drafts-list .div-list .div-list-item div.messageType .icons,
body.drafts div.conversations-list .div-list .div-list-item div.messageType .icons,
body.drafts md-content.drafts-list .div-list .div-list-item div.messageType .icons {
  position: absolute;
  bottom: 5px;
  right: 8px;
  color: #000;
}
body.conversations div.conversations-list .div-list .div-list-item div.messageType .icons md-icon,
body.conversations md-content.drafts-list .div-list .div-list-item div.messageType .icons md-icon,
body.drafts div.conversations-list .div-list .div-list-item div.messageType .icons md-icon,
body.drafts md-content.drafts-list .div-list .div-list-item div.messageType .icons md-icon {
  font-size: 16px;
  width: 16px;
  height: 16px;
}
body.conversations div.conversations-list .div-list .div-list-item div.messageType .icons md-icon.new,
body.conversations md-content.drafts-list .div-list .div-list-item div.messageType .icons md-icon.new,
body.drafts div.conversations-list .div-list .div-list-item div.messageType .icons md-icon.new,
body.drafts md-content.drafts-list .div-list .div-list-item div.messageType .icons md-icon.new {
  color: #FF3600;
}
body.conversations div.conversations-list .div-list .div-list-item div.messageType .icons md-icon.attach-file,
body.conversations md-content.drafts-list .div-list .div-list-item div.messageType .icons md-icon.attach-file,
body.drafts div.conversations-list .div-list .div-list-item div.messageType .icons md-icon.attach-file,
body.drafts md-content.drafts-list .div-list .div-list-item div.messageType .icons md-icon.attach-file {
  transform: rotate(45deg);
  color: #757575;
}
body.conversations div.conversations-list .div-list div.load-more,
body.conversations md-content.drafts-list .div-list div.load-more,
body.drafts div.conversations-list .div-list div.load-more,
body.drafts md-content.drafts-list .div-list div.load-more {
  text-align: left;
  padding: 0 0 0 20px;
  line-height: 75px;
}
body.conversations div.conversations-list .div-list div.load-more a,
body.conversations md-content.drafts-list .div-list div.load-more a,
body.drafts div.conversations-list .div-list div.load-more a,
body.drafts md-content.drafts-list .div-list div.load-more a {
  color: #000;
  padding: 1em;
}
body.conversations .middle-list-header,
body.drafts .middle-list-header {
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}
body.conversations h2,
body.drafts h2 {
  overflow: hidden;
  text-overflow: ellipsis;
}
body.conversations .label-name,
body.drafts .label-name {
  white-space: pre;
}
body.conversations .label-menu,
body.drafts .label-menu {
  position: relative;
  display: inline-block;
  line-height: 50px;
  padding-top: 7px;
  width: 40px;
}
body.conversations .chevron-quickselect,
body.drafts .chevron-quickselect {
  margin: 0;
  padding: 0;
}
body.conversations md-dialog,
body.drafts md-dialog {
  border-radius: 10px;
}
body.conversations md-dialog md-toolbar,
body.drafts md-dialog md-toolbar {
  height: 60px !important;
  min-height: 60px !important;
  background-color: #FFFFFF !important;
  color: #1F1F1F !important;
  border-bottom: 1px solid #E0E0E0;
}
body.conversations md-dialog md-toolbar .md-toolbar-tools,
body.drafts md-dialog md-toolbar .md-toolbar-tools {
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
body.conversations md-dialog md-toolbar md-icon,
body.drafts md-dialog md-toolbar md-icon {
  color: #1F1F1F !important;
}
body.conversations md-dialog md-dialog-content,
body.drafts md-dialog md-dialog-content {
  padding: 24px;
}
body.conversations md-dialog md-dialog-content md-content,
body.drafts md-dialog md-dialog-content md-content {
  background-color: transparent;
}
body.conversations md-dialog md-dialog-actions,
body.drafts md-dialog md-dialog-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  margin: 0;
}
body.conversations md-dialog md-dialog-actions.single-action,
body.drafts md-dialog md-dialog-actions.single-action {
  justify-content: flex-end;
}
body.conversations md-dialog md-dialog-actions .md-button,
body.drafts md-dialog md-dialog-actions .md-button {
  border-radius: 4px;
  margin: 0 !important;
}

body.conversations aside md-icon {
  color: #FFFFFF;
}
body.conversations aside md-icon .grey {
  color: #757575;
}
body.conversations aside .title h2 {
  text-align: left;
}
body.conversations aside .search-container {
  margin: 0 10px 0 0;
  /* hide X icon on Edge */
}
body.conversations aside .search-container .md-errors-spacer {
  display: none;
}
body.conversations aside .search-container .md-input {
  color: #111;
  border-bottom: none;
}
body.conversations aside .search-container ::placeholder {
  color: #96999e;
  opacity: 0.6;
}
body.conversations aside .search-container input.search-input {
  position: relative;
}
@media (max-width: 959px) {
  body.conversations aside .search-container input.search-input {
    bottom: 0;
    margin-left: 10px;
  }
}
body.conversations aside .search-container input.search-input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
body.conversations .conversation-view {
  width: 100%;
  overflow: hidden;
}
body.conversations .conversation-view .conversation-label-container {
  padding-left: 8px;
  padding-bottom: 8px;
}
body.conversations .conversation-view .conversation-label-container::after {
  content: "";
  display: block;
  clear: both;
}
body.conversations .conversation-view .conversation-container {
  padding-top: 8px;
}
body.conversations .conversation-view .conversation-container.ordinary {
  height: 100%;
}
body.conversations .conversation-view .conversation-container .conversation-messages {
  overflow-y: auto;
  overflow-x: hidden;
}
body.conversations .conversation-view .conversation-container .conversation-messages md-card:first-child {
  margin-top: 0;
}
@media (max-width: 959px) {
  body.conversations .conversation-view .conversation-container .conversation-messages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}
body.conversations .conversation-view md-card.challenge {
  max-width: 480px;
}
body.conversations .conversation-view md-card.placeholder md-card-avatar div {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
@media all and (-ms-high-contrast: none) {
  body.conversations .conversation-view md-card.placeholder md-card-content {
    visibility: hidden;
  }
}
@keyframes placeholder-fader {
  0%, 100% {
    background: #FAFAFA;
  }
  50% {
    background: #E0E0E0;
  }
}
body.conversations .conversation-view md-card.placeholder .block {
  animation: placeholder-fader 1s infinite;
}
body.conversations .conversation-view md-card.placeholder .md-title {
  width: 50%;
}
body.conversations .conversation-view md-card.placeholder p {
  width: 80%;
}
body.conversations .conversation-view md-card.placeholder p:nth-child(2) {
  width: 70%;
}
body.conversations .conversation-reply-header aside .md-menu md-icon .grey {
  color: #9E9E9E;
}
body.conversations .conversation-reply-header aside .title h2 {
  line-height: normal;
  text-align: inherit;
}
body.conversations .conversation-reply-header aside .title .summary {
  color: #111;
  font-size: 15px;
  width: 100%;
  text-align: inherit;
}
body.conversations .conversation-reply-header aside .title .summary .recipients {
  white-space: pre;
  float: left;
  width: calc(100% - 2em);
  overflow: hidden;
  text-overflow: ellipsis;
}
body.conversations .conversation-reply-header aside .title .summary .recipient-icon {
  font-size: 18px !important;
  float: left;
}
body.conversations .conversation-reply .recipients {
  opacity: 0.8;
  cursor: not-allowed;
}
body.conversations .conversation-reply .recipients > * {
  pointer-events: none;
}
body.conversations .conversation-reply .subject {
  cursor: not-allowed;
}
body.conversations .conversation-reply .subject > * {
  pointer-events: none;
}
body.conversations .conversation-reply .subject md-input-container {
  margin: 24px 0;
  padding-bottom: 0;
}
body.conversations .conversation-reply .subject md-input-container input {
  color: #757575;
}
body.conversations .conversation-reply md-input-container {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  width: 100%;
}
body.conversations .conversation-reply md-input-container draft-body #pell .pell-content {
  min-height: 280px;
}
body.conversations .conversation-reply md-input-container draft-body div.draft-text div.ql-editor,
body.conversations .conversation-reply md-input-container draft-body textarea {
  min-height: 280px;
}
body.conversations .conversation-reply #conversation-reply-messages {
  display: none;
}
body.conversations .conversation-reply section {
  width: 100%;
}
body.conversations .conversation-reply section.actions .time-left {
  color: #000;
}
body.conversations .conversation-reply section.actions .editor-button {
  border-radius: 0;
}
body.conversations .conversation-reply section.actions .editor-button md-icon {
  width: 28px;
  height: 28px;
  font-size: 28px;
}
body.conversations .conversation-reply section.actions .editor-button .rich-text {
  background-color: rgba(31, 31, 31, 0.122);
  border-radius: 4px;
}