/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
html body.app {
  overflow: hidden;
}
html body.app .md-button:not(.md-fab):not(.md-no-style), html body.app .md-button:not(.md-fab).md-raised {
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
}
html body.app .main-view {
  height: 100%;
  width: 100%;
}
html body.app .main-view .zivver-main {
  height: 100%;
  width: 100%;
}
html body.app .main-view .zivver-main.verify {
  background: #f5f5f5;
  overflow: auto;
}
html body.app .main-view .zivver-main .app-container {
  height: 100%;
}
html body.app.login-refresh .app-container md-card {
  max-width: 500px;
  margin: 32px auto;
}
html body.app.login-refresh .app-container md-card md-card-title, html body.app.login-refresh .app-container md-card md-card-content {
  width: 100%;
}
html body.app .app-container.no-guest {
  margin-left: 54px;
}
@media (max-width: 959px) {
  html body.app .app-container.no-guest {
    margin-left: 0;
  }
}
@media (max-width: 959px) {
  @-moz-document url-prefix() {
    html body.app .app-container.no-guest :not(body[state="conversations.view"]) .main-content:after {
      display: none;
    }
  }
  html body.app .app-container.no-guest .main-content {
    padding-bottom: 0px;
  }
}
html body.app md-dialog {
  border-radius: 10px;
}
html body.app md-dialog md-toolbar {
  height: 60px !important;
  min-height: 60px !important;
  background-color: #FFFFFF !important;
  color: #1F1F1F !important;
  border-bottom: 1px solid #e7eaee;
}
html body.app md-dialog md-toolbar .md-toolbar-tools {
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
html body.app md-dialog md-toolbar md-icon {
  color: #1F1F1F !important;
}
html body.app md-dialog md-dialog-content {
  padding: 24px;
}
html body.app md-dialog md-dialog-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

header.app {
  background-color: #FFFFFF;
  height: 64px;
  padding: 0 12px 0 16px;
  z-index: 3;
  border-bottom: 1px solid #E0E0E0;
}
header.app h1 {
  margin: 0;
  width: initial;
}
header.app h1 a {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  background: transparent url("./logo.svg") left center no-repeat;
  display: block;
  width: 105px;
  height: 37px;
  background-size: contain;
}
@media (max-width: 599px) {
  header.app {
    padding: 0 12px 0 6px;
  }
  body[state="conversations.view"] header.app:not(.guest),
  body[state="drafts.special"] header.app:not(.guest),
  body[state="drafts.specialedit"] header.app:not(.guest) {
    display: none;
  }
  body[state="conversations.view"] div.body,
  body[state="drafts.special"] div.body,
  body[state="drafts.specialedit"] div.body {
    height: 100%;
    margin-top: 0;
  }
  body[state="conversations.view"] div.body .column-content .main-content,
  body[state="drafts.special"] div.body .column-content .main-content,
  body[state="drafts.specialedit"] div.body .column-content .main-content {
    margin-top: 0;
    max-width: 100%;
    height: calc(100% - 64px);
    max-height: calc(100% - 64px);
  }
}

@media all and (-ms-high-contrast: none) {
  header.guest language-select label {
    position: relative;
    bottom: 9px;
  }
  header.guest language-select md-select {
    position: relative;
    top: 7px;
  }
}
@-moz-document url-prefix() {
  header.guest language-select label {
    position: relative;
    bottom: 9px;
  }
  header.guest language-select md-select {
    position: relative;
    top: 7px;
  }
}
@media (max-width: 599px) {
  header.guest h1 {
    margin-left: 8px;
  }
}

div.body {
  height: calc(100% - 64px);
  background-color: transparent;
}
div.body .column-list {
  width: 350px;
  height: 100%;
  border-radius: 0 0 2px 2px;
  left: 64px;
}
@media (max-width: 959px) {
  div.body .column-list {
    margin-left: 0;
  }
}
div.body .column-list .header {
  background-color: #fff;
  border-bottom: 1px solid #e7eaee;
}
div.body .column-list .header .md-button md-icon {
  color: #111;
}
div.body .column-list .header .no-multiselect-margin {
  margin-left: 16px;
}
div.body .column-list .header h2 {
  text-align: left;
  white-space: nowrap;
}
div.body .column-list md-content {
  background-color: #FFFFFF;
  border-right: 1px solid #E0E0E0;
  height: calc(100% - 64px);
  border-radius: 0 0 2px 2px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
div.body .column-list .div-list {
  height: 100%;
  padding-top: 0;
}
div.body .column-list .div-list .div-list-item:focus {
  outline: none;
}
div.body .column-content {
  height: 100%;
}
div.body .column-content .main {
  height: 100%;
}
div.body .column-content .zv-subheader aside.header.middle {
  max-width: 920px;
  width: 100%;
  padding: 0 16px;
}
div.body .column-content .zv-subheader aside.header.middle aside.header.middle {
  background-color: transparent;
  box-shadow: none;
}
@media (max-width: 959px) {
  div.body .column-content .zv-subheader aside.header.middle {
    padding-left: 0;
  }
}
div.body .column-content .main-content {
  width: 100%;
  max-width: 920px;
  height: calc(100% - 64px);
  min-height: calc(100% - 64px);
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  padding-bottom: 60px;
}
@-moz-document url-prefix() {
  div.body .column-content .main-content:not(body[state="conversations.view"])::after {
    content: "";
    height: 60px;
    display: block;
  }
}
div.body.guest .column-content .zv-subheader {
  left: 0;
}
div.body.verify {
  display: block;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-shadow: none;
}
@media (min-width: 601px) {
  div.body.verify {
    margin: 10% auto;
    width: 500px;
    max-width: 95%;
    padding: 1em;
    box-shadow: 0px 1px 4px rgba(37, 69, 105, 0.1), 0px 3px 12px rgba(37, 69, 105, 0.1);
  }
}
div.body aside.header {
  height: 64px;
  background-color: #fff;
  border-bottom: 1px solid #e7eaee;
  color: #111;
}
div.body aside.header h2, div.body aside.header h3 {
  font-size: 20px;
  margin: 0;
  color: #111;
  line-height: 64px;
  font-weight: normal;
  text-align: left;
}
div.body aside.header md-icon {
  color: #111;
}
div.body aside.header.burger .md-button md-icon {
  color: #111;
}
div.body aside.header back-button .back-arrow {
  font-size: 28px;
  color: #000;
}
div.body aside.header .title > h2 {
  text-align: left !important;
  margin-top: 3px !important;
}
div.body aside.header md-input-container.search {
  margin: 0 10px 0 0;
}
div.body aside.header md-input-container.search .md-errors-spacer {
  display: none;
}
div.body aside.header md-input-container.search .md-input {
  color: #6f7175;
  border-bottom: none;
}
div.body aside.header md-input-container.search ::-webkit-input-placeholder,
div.body aside.header md-input-container.search ::-moz-placeholder,
div.body aside.header md-input-container.search :-ms-input-placeholder {
  color: #96999e;
  opacity: 0.6;
}
div.body md-list-item {
  border-bottom: 1px solid #e7eaee;
}
div.body md-list-item.active {
  background-color: #fbf3ef;
  color: #EEEEEE;
}
div.body md-list-item.active .md-list-item-text h4, div.body md-list-item.active .md-list-item-text p {
  color: #1F1F1F;
}
div.body md-list-item.active:hover {
  background-color: #f5f5f5;
}
div.body md-list-item .wrap {
  word-wrap: break-word;
}
div.body .fill-width {
  width: 100%;
}

/*
	The index on xs and sm screens will hide the column-content
	and display the column-list full width, with the right colors and now shadows
*/
@media (max-width: 959px) {
  div.body div.column-sidenav {
    display: none;
  }
  body[state$=".index"] div.body div.column-content,
  body[state="conversations.label"] div.body div.column-content,
  body[state="conversations.search"] div.body div.column-content,
  body[state="contacts.personal"] div.body div.column-content,
  body[state="contacts.all"] div.body div.column-content,
  body[state="contacts.organization"] div.body div.column-content,
  body[state="contacts.shared"] div.body div.column-content,
  body[state=settings] div.body div.column-content,
  body[state=organization] div.body div.column-content {
    display: none;
  }
  body[state$=".index"] div.body div.column-list,
  body[state="conversations.label"] div.body div.column-list,
  body[state="conversations.search"] div.body div.column-list,
  body[state="contacts.personal"] div.body div.column-list,
  body[state="contacts.all"] div.body div.column-list,
  body[state="contacts.organization"] div.body div.column-list,
  body[state="contacts.shared"] div.body div.column-list,
  body[state=settings] div.body div.column-list,
  body[state=organization] div.body div.column-list {
    width: 100%;
    left: 0;
    position: inherit;
    filter: none;
    -webkit-filter: none;
  }
  body[state$=".index"] div.body div.column-list aside.header,
  body[state="conversations.label"] div.body div.column-list aside.header,
  body[state="conversations.search"] div.body div.column-list aside.header,
  body[state="contacts.personal"] div.body div.column-list aside.header,
  body[state="contacts.all"] div.body div.column-list aside.header,
  body[state="contacts.organization"] div.body div.column-list aside.header,
  body[state="contacts.shared"] div.body div.column-list aside.header,
  body[state=settings] div.body div.column-list aside.header,
  body[state=organization] div.body div.column-list aside.header {
    background-color: #fff;
    border-bottom: 1px solid #e7eaee;
    left: 0;
    top: 64px;
    width: 100%;
    z-index: 10;
  }
  body[state$=".index"] div.body div.column-list md-content md-list,
  body[state="conversations.label"] div.body div.column-list md-content md-list,
  body[state="conversations.search"] div.body div.column-list md-content md-list,
  body[state="contacts.personal"] div.body div.column-list md-content md-list,
  body[state="contacts.all"] div.body div.column-list md-content md-list,
  body[state="contacts.organization"] div.body div.column-list md-content md-list,
  body[state="contacts.shared"] div.body div.column-list md-content md-list,
  body[state=settings] div.body div.column-list md-content md-list,
  body[state=organization] div.body div.column-list md-content md-list {
    padding-bottom: 0;
  }
  body[state$=".index"] div.body.guest div.column-content,
  body[state="conversations.label"] div.body.guest div.column-content,
  body[state="conversations.search"] div.body.guest div.column-content,
  body[state="contacts.personal"] div.body.guest div.column-content,
  body[state="contacts.all"] div.body.guest div.column-content,
  body[state="contacts.organization"] div.body.guest div.column-content,
  body[state="contacts.shared"] div.body.guest div.column-content,
  body[state=settings] div.body.guest div.column-content,
  body[state=organization] div.body.guest div.column-content {
    display: inline-block;
  }
  body:not([state$=".index"]):not([state="conversations.label"]):not([state="conversations.search"]):not([state="contacts.personal"]):not([state="contacts.all"]):not([state="contacts.organization"]):not([state="contacts.shared"]):not([state=settings]):not([state=organization]) div.body div.column-list {
    display: none;
  }
}
body[state^=organization] md-input-container, body[state^=settings] md-input-container, body[state^=contacts] md-input-container {
  margin-top: 9px;
  margin-bottom: 9px;
}
body[state^=organization] md-input-container label:not(.md-no-float):not(.md-container-ignore), body[state^=settings] md-input-container label:not(.md-no-float):not(.md-container-ignore), body[state^=contacts] md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  padding-bottom: 3px;
  padding-left: 8px;
}
body[state^=organization] md-input-container .md-input, body[state^=organization] md-input-container md-select, body[state^=settings] md-input-container .md-input, body[state^=settings] md-input-container md-select, body[state^=contacts] md-input-container .md-input, body[state^=contacts] md-input-container md-select {
  background-color: #FAFAFA;
  border: 1px solid #e7eaee;
  border-radius: 3px;
  color: #111;
  padding: 8px;
}
body[state^=organization] md-input-container .md-input.ng-invalid.ng-dirty, body[state^=organization] md-input-container md-select.ng-invalid.ng-dirty, body[state^=settings] md-input-container .md-input.ng-invalid.ng-dirty, body[state^=settings] md-input-container md-select.ng-invalid.ng-dirty, body[state^=contacts] md-input-container .md-input.ng-invalid.ng-dirty, body[state^=contacts] md-input-container md-select.ng-invalid.ng-dirty {
  border-width: 1px;
  border-color: #E81B42;
  padding: 8px;
}
body[state^=organization] md-input-container md-select, body[state^=settings] md-input-container md-select, body[state^=contacts] md-input-container md-select {
  padding: 0 0 0 4px !important;
  height: 30px;
}
body[state^=organization] md-input-container md-select .md-select-value, body[state^=settings] md-input-container md-select .md-select-value, body[state^=contacts] md-input-container md-select .md-select-value {
  border-bottom-width: 0 !important;
  min-height: 28px;
  padding: 0;
}
body[state^=organization] md-input-container md-select[disabled] .md-select-value, body[state^=settings] md-input-container md-select[disabled] .md-select-value, body[state^=contacts] md-input-container md-select[disabled] .md-select-value {
  margin: 0;
  padding-bottom: 1px;
}
body[state^=organization] md-input-container.md-input-focused .md-input, body[state^=settings] md-input-container.md-input-focused .md-input, body[state^=contacts] md-input-container.md-input-focused .md-input {
  border-width: 1px;
  border-color: #000;
  padding: 8px;
}
body[state^=organization] md-input-container.md-input-focused .md-input.ng-invalid.ng-dirty, body[state^=settings] md-input-container.md-input-focused .md-input.ng-invalid.ng-dirty, body[state^=contacts] md-input-container.md-input-focused .md-input.ng-invalid.ng-dirty {
  border-color: #E81B42;
}

/*
	Some states will want to hide the column-list at all resolutions
*/
body[state=specials-about] div.body div.column-list {
  width: 0;
}

body[state^=organization] sidenav, body[state^=organization] .column-list {
  display: none;
}
body[state^=organization] .app-container {
  margin-left: 0 !important;
}
body[state^=organization] .app-container .burger {
  display: none;
}

@media all and (-ms-high-contrast: none) {
  body.app {
    background-attachment: scroll;
  }
}
div#noprinting {
  display: none;
}

@media print {
  html, html body {
    background: transparent;
    width: auto;
    height: auto;
  }
  html body.app div.main-view div#zivver-main {
    display: none;
  }
  html body.app div.main-view div#noprinting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }
  html body.app div.main-view div#noprinting span.no-print-message {
    display: inline-block;
    font-size: 1.5em;
    text-align: center;
    color: #757575;
  }
  html body.app div.main-view div#noprinting span.no-print-message md-icon {
    color: #1F1F1F;
  }
}