/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
conversation-label {
  display: block;
  float: left;
}
conversation-label .pricetag {
  box-sizing: content-box;
  border: 1px solid;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  height: 12px;
  line-height: 12px;
  white-space: nowrap;
  font-size: 12px;
  padding: 2px 5px 2px 7px;
  margin: 0 5px 0 0;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: 15px;
}
conversation-label .pricetag .remove {
  float: right;
  outline: none;
  margin-right: -10px;
  position: relative;
}
conversation-label .pricetag .remove .material-icons {
  font-size: 12px;
  width: 12px;
  min-height: 0;
  min-width: 0;
}
conversation-label .pricetag .pricetag-triangle {
  width: 1em;
  height: 1em;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  right: -0.5em;
  top: 0.2em;
  border-top: 1px solid;
  border-right: 1px solid;
  border-top-right-radius: 3px;
  transform: rotate(45deg);
}
conversation-label .pricetag-mini {
  color: #1F1F1F;
  height: 10px;
  font-size: 10px;
  margin: 0 8px 2px 0;
  padding: 1px 5px;
  white-space: nowrap;
  display: inline-block;
  line-height: 10px;
}