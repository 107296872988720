/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
avatar .picture {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  color: #111;
  border-radius: 100%;
}
avatar .picture.xs {
  height: 20px;
  width: 20px;
  font-size: 16px;
  margin: 1px;
}
avatar .picture.sm {
  height: 32px;
  width: 32px;
  font-size: 24px;
  position: relative;
}
avatar .picture.md {
  height: 40px;
  width: 40px;
  font-size: 32px;
}
avatar .picture.lg {
  width: 48px;
  height: 48px;
  font-size: 36px;
}
avatar .picture.xl {
  height: 64px;
  width: 64px;
  font-size: 40px;
}
avatar .picture.xxl {
  width: 92px;
  height: 92px;
  font-size: 56px;
}
avatar div.disabled, avatar img.disabled {
  background-color: #9E9E9E !important;
}
avatar img {
  font-size: 14px;
}