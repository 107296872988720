/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
.wizard-businessrules {
  display: block;
}
.wizard-businessrules .placeholder-main img {
  margin: 50px auto 0 auto !important;
}
.wizard-businessrules .placeholder-main p a.link {
  color: #000;
  text-decoration: underline;
}

md-dialog.wizard-businessrules md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
  min-height: 415px !important;
}