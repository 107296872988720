/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
div.auth main .login div.actions {
  justify-content: space-between;
}
div.auth main .login md-icon {
  top: 5px;
}

div.auth div.login-extra-factor div.actions {
  justify-content: space-between;
}
div.auth div.login-extra-factor md-icon {
  color: #000;
}
div.auth div.login-extra-factor .trusted-device {
  padding-left: 3px;
  color: #757575;
}

div.auth.login-401 main div.message {
  margin-top: 1em;
}
div.auth.login-401 main div.message md-icon {
  color: #FB743D;
}
div.auth.login-401 main div.message p {
  text-align: left;
  margin-top: 0;
}
div.auth.login-401 main div.message p .md-button span {
  color: rgba(255, 255, 255, 0.87);
}
div.auth.login-401 main div.message p:last-child {
  margin-bottom: 0;
}
div.auth.login-401 main div.message ul {
  margin: 0;
  margin-left: 1.2em;
  padding: 0;
  list-style-type: square;
  color: #757575;
}

div.auth div.login-sso-conflict {
  line-height: 1.5;
  padding: 20px;
}
div.auth div.login-sso-conflict div.primary-call-to-action {
  text-align: center;
}
div.auth div.login-sso-conflict div.alternative-action ol li {
  text-align: left;
}

body.login md-dialog {
  border-radius: 10px;
}
body.login md-dialog-actions {
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #E0E0E0;
  padding: 24px;
  margin: 0;
}
body.login md-dialog-actions .md-button {
  border-radius: 4px;
  margin: 0 !important;
}