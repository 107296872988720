/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
sidenav {
  height: 100%;
  display: block;
  float: left;
}
sidenav #overlay {
  background-color: rgba(31, 31, 31, 0.75);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}
sidenav > div.wrapper {
  background-color: #e9edf0;
  border-right: 1px solid #E0E0E0;
  display: inline-flex;
  font-size: 0.9em;
  height: 100%;
  position: fixed;
  user-select: none;
  z-index: 4;
  flex-direction: column;
  justify-content: flex-start;
}
@media (max-width: 959px) {
  sidenav > div.wrapper {
    overflow-y: auto; /* has to be scroll, not auto */
    overflow-scrolling: touch;
  }
}
sidenav > div.wrapper header {
  height: 64px;
  line-height: 64px;
  margin-bottom: 10px;
  text-align: right;
  width: 100%;
}
@media screen and (min-width: 0\0 ) {
  sidenav > div.wrapper header {
    margin-bottom: 0px !important;
  }
}
sidenav > div.wrapper header .rotatable {
  animation: rotate0 250ms;
}
sidenav > div.wrapper header .rotatable.rotated {
  animation: rotate90 250ms;
  transform: rotate(90deg);
}
@media (max-width: 599px) {
  sidenav > div.wrapper header .md-button.set-state-open, sidenav > div.wrapper header .md-button.set-state-pinned, sidenav > div.wrapper header .md-button.set-state-unpin {
    display: none;
  }
}
sidenav > div.wrapper header .md-button md-icon {
  color: #111;
}
sidenav > div.wrapper div.sections-wrapper {
  display: flex;
  height: inherit;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: flex-start;
}
@media (max-width: 599px) {
  sidenav > div.wrapper div.sections-wrapper .hidden {
    display: none;
  }
}
sidenav > div.wrapper div.sections-wrapper section {
  width: 100%;
}
sidenav > div.wrapper div.sections-wrapper section.settings {
  margin-top: auto;
}
sidenav > div.wrapper div.sections-wrapper h2 {
  font-size: 0.95em;
  font-weight: normal;
  line-height: 40px;
  margin: 0;
  padding: 0;
  text-indent: 15px;
  text-transform: uppercase;
  color: #111;
}
sidenav > div.wrapper div.sections-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
sidenav > div.wrapper div.sections-wrapper ul li {
  line-height: 40px;
  border-left: 3px solid transparent;
  color: #111;
}
sidenav > div.wrapper div.sections-wrapper ul li md-icon {
  color: #111;
}
sidenav > div.wrapper div.sections-wrapper ul li img {
  vertical-align: middle;
}
sidenav > div.wrapper div.sections-wrapper ul li:focus {
  outline: none;
}
sidenav > div.wrapper div.sections-wrapper ul li:hover {
  background-color: #D6DBE2;
  transition: color 150ms, background-color 150ms;
}
sidenav > div.wrapper div.sections-wrapper ul li:hover md-icon {
  color: #222;
}
sidenav > div.wrapper div.sections-wrapper ul li.active {
  background-color: #fbf3ef;
  border-left-color: #FF3600;
  color: #111;
}
sidenav > div.wrapper div.sections-wrapper ul li.active md-icon {
  color: #111;
}
sidenav > div.wrapper div.sections-wrapper ul li.child {
  padding-left: 7px;
}
sidenav > div.wrapper div.sections-wrapper ul li.child md-icon {
  font-size: 0.9em;
}
sidenav > div.wrapper.state-closed-add {
  transition: width 125ms ease-in;
}
sidenav > div.wrapper.state-closed {
  width: 54px;
  text-align: left;
  overflow-y: hidden;
}
sidenav > div.wrapper.state-closed app-switcher, sidenav > div.wrapper.state-closed .close-button {
  display: none;
}
@media (max-width: 959px) {
  sidenav > div.wrapper.state-closed {
    width: 0;
  }
}
sidenav > div.wrapper.state-closed div.sections-wrapper ul {
  margin: 0;
  width: 54px;
}
sidenav > div.wrapper.state-closed div.sections-wrapper section {
  width: 24px;
}
sidenav > div.wrapper.state-closed div.sections-wrapper section h2 {
  display: none;
}
sidenav > div.wrapper.state-closed div.sections-wrapper section:not(.settings):not(.rest) {
  border-top: 1px solid transparent;
  margin-top: 15px;
  padding-top: 15px;
}
@media screen and (min-width: 0\0 ) {
  sidenav > div.wrapper.state-closed div.sections-wrapper section:not(.settings):not(.rest) {
    margin-top: 24px;
  }
}
sidenav > div.wrapper.state-closed div.sections-wrapper section:not(.settings):not(.rest) h2 {
  display: none;
}
sidenav > div.wrapper.state-closed div.sections-wrapper section.rest {
  text-align: center;
}
sidenav > div.wrapper.state-closed div.sections-wrapper li {
  text-align: center;
}
sidenav > div.wrapper.state-closed div.sections-wrapper li.hide-when-mini, sidenav > div.wrapper.state-closed div.sections-wrapper li .hide-when-mini {
  display: none;
}
sidenav > div.wrapper.state-closed div.sections-wrapper li span {
  display: none;
}
sidenav > div.wrapper.state-closed div.sections-wrapper version {
  visibility: hidden;
  height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}
sidenav > div.wrapper.state-open-add {
  transition: width 125ms ease-in;
}
sidenav > div.wrapper.state-open, sidenav > div.wrapper.state-pinned {
  text-align: left;
  width: 250px;
}
sidenav > div.wrapper.state-open header, sidenav > div.wrapper.state-pinned header {
  margin-bottom: 1px;
  text-align: left;
}
sidenav > div.wrapper.state-open header app-switcher, sidenav > div.wrapper.state-pinned header app-switcher {
  display: block;
  float: left;
}
sidenav > div.wrapper.state-open header div.icons, sidenav > div.wrapper.state-pinned header div.icons {
  float: right;
}
sidenav > div.wrapper.state-open div.sections-wrapper, sidenav > div.wrapper.state-pinned div.sections-wrapper {
  overflow-y: auto;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul, sidenav > div.wrapper.state-pinned div.sections-wrapper ul {
  text-indent: 7px;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul li, sidenav > div.wrapper.state-pinned div.sections-wrapper ul li {
  overflow: hidden;
  white-space: nowrap;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul li md-icon, sidenav > div.wrapper.state-pinned div.sections-wrapper ul li md-icon {
  margin-right: 15px;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul li img, sidenav > div.wrapper.state-pinned div.sections-wrapper ul li img {
  margin-left: 6px;
  margin-right: 9px;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul li.active, sidenav > div.wrapper.state-pinned div.sections-wrapper ul li.active {
  background-color: #fbf3ef;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul ul, sidenav > div.wrapper.state-pinned div.sections-wrapper ul ul {
  max-height: 200px;
  min-width: 230px;
  overflow-x: hidden;
  overflow-y: auto;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul ul li, sidenav > div.wrapper.state-pinned div.sections-wrapper ul ul li {
  line-height: 15px;
  overflow: hidden;
  padding: 7px 0px;
  text-indent: 10px;
  text-overflow: ellipsis;
  white-space: normal;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul ul li md-icon, sidenav > div.wrapper.state-pinned div.sections-wrapper ul ul li md-icon {
  display: inline-block;
  margin-top: 6px;
  vertical-align: top;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul ul li span.name, sidenav > div.wrapper.state-pinned div.sections-wrapper ul ul li span.name {
  display: inline-block;
  margin-top: 5px;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
sidenav > div.wrapper.state-open div.sections-wrapper ul ul li span.counter, sidenav > div.wrapper.state-pinned div.sections-wrapper ul ul li span.counter {
  float: right;
  display: inline-block;
  margin-right: 19px;
  margin-top: 5px;
}
sidenav > div.wrapper.state-open div.sections-wrapper section.conversations ul li .toggle, sidenav > div.wrapper.state-pinned div.sections-wrapper section.conversations ul li .toggle {
  margin-left: 117px;
}
sidenav > div.wrapper.state-open div.sections-wrapper version, sidenav > div.wrapper.state-pinned div.sections-wrapper version {
  margin-left: 5px;
}
sidenav > div.wrapper.state-pinned {
  position: static;
  height: 100%;
}
@media screen and (min-width: 0\0 ) {
  sidenav > div.wrapper.state-pinned header {
    padding-bottom: 8px;
  }
}
@keyframes rotate90 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}
@keyframes rotate0 {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0deg);
  }
}