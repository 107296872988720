/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
md-dialog.services-dialog {
  width: 360px;
  max-width: 100%;
}
md-dialog.services-dialog md-dialog-content {
  padding: 20px;
  margin-top: 8px;
}
md-dialog.services-dialog md-dialog-actions {
  padding: 0 8px;
}
md-dialog.services-dialog md-dialog-actions button {
  margin-left: 0;
}
md-dialog.services-dialog .grid-list {
  max-height: 300px;
  overflow-x: hidden;
  padding: 2px;
  margin-right: -20px;
  padding-right: 20px;
}
md-dialog.services-dialog md-progress-circular {
  margin: 30px auto !important;
}
md-dialog.services-dialog md-grid-list {
  margin-top: 25px;
}
md-dialog.services-dialog md-grid-list md-grid-tile {
  background: rgba(0, 186, 155, 0.38);
}
md-dialog.services-dialog md-grid-list md-grid-tile.active {
  border: 1px solid #00BA9B;
}
md-dialog.services-dialog md-grid-list md-icon {
  position: relative;
  top: -12px;
}
md-dialog.services-dialog md-grid-tile-footer {
  height: 25px;
  background: rgba(31, 31, 31, 0.38);
}
md-dialog.services-dialog md-grid-tile-footer figcaption h3 {
  margin: 0;
  font-weight: 500;
  width: 90px;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px 5px;
}
md-dialog.services-dialog .dialog-info {
  margin-top: 25px;
}