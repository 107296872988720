/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
.format-size-container {
  width: 180px;
  height: 128px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(31, 31, 31, 0.14), 0 3px 14px 2px rgba(31, 31, 31, 0.12), 0 5px 5px -3px rgba(31, 31, 31, 0.2);
}
.format-size-container .size-row {
  height: 32px;
  padding-left: 8px;
}
.format-size-container .size-row:hover {
  background-color: rgba(224, 224, 224, 0.4);
  color: #000;
}
.format-size-container .size-row.small {
  font-size: x-small;
}
.format-size-container .size-row.large {
  font-size: large;
}
.format-size-container .size-row.extra-large {
  font-size: x-large;
}
.format-size-container .size-row.md-primary {
  background-color: #fbf3ef;
}
.format-size-container .size-row.md-primary > translate {
  color: #000;
}
.format-size-container .size-row.md-primary:hover {
  color: #f5f5f5;
}