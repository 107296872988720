/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
profile-menu .profile {
  max-height: 48px;
}

md-menu-content.profile-menu {
  max-height: 320px !important;
}
md-menu-content.profile-menu .account-list {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
md-menu-content.profile-menu .account-list .menu-header {
  height: 80px;
  outline: none;
}
md-menu-content.profile-menu .account-list .menu-header.account-option {
  height: 60px !important;
  min-height: 60px;
}
md-menu-content.profile-menu .account-list .menu-header.account-option div {
  max-width: 300px;
}
md-menu-content.profile-menu .account-list .menu-header.account-option div .full-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
md-menu-content.profile-menu .account-list .menu-header.account-option avatar {
  width: auto;
  padding-right: 0;
}
md-menu-content.profile-menu .account-list .menu-header.account-option md-icon {
  color: #000;
  padding: 0;
  margin-right: 10px;
  width: auto;
}
md-menu-content.profile-menu .account-list .menu-header.account-option .email {
  color: #6f7175;
}
md-menu-content.profile-menu .actions-list .edit-profile {
  margin-left: 0;
}
md-menu-content.profile-menu .actions-list .log-out {
  margin-right: 0;
}

md-radio-button .md-label {
  flex-direction: row;
  align-items: center;
}
md-radio-button .md-label md-icon {
  margin-left: 0;
  margin-right: 8px;
}