/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
conversation-quote {
  display: block;
}
conversation-quote div.message-quoted {
  transition: border 125ms ease-in-out;
  background-color: transparent;
  min-height: 55px;
  padding-bottom: 10px;
  border: 1px solid #e7eaee;
  border-left: 4px solid #000;
  border-radius: 4px;
  padding: 8px;
  margin: 6px 16px;
}
@media (min-width: 601px) {
  conversation-quote div.message-quoted {
    flex-wrap: wrap;
  }
}
conversation-quote div.message-quoted.header {
  padding: 4px 16px !important;
}
conversation-quote div.message-quoted.revoked {
  background-color: #EEEEEE;
  border-left: 4px solid #9E9E9E;
  color: #9E9E9E;
}
conversation-quote div.message-quoted.revoked .info .conversation-quote {
  font-weight: bold;
}
conversation-quote div.message-quoted.revoked conversation-event > div {
  background-color: transparent;
  border: 0;
}
conversation-quote div.message-quoted.revoked conversation-event > div time {
  display: none;
}
conversation-quote div.message-quoted.bcc div.labels span {
  position: relative;
  top: -28px;
  left: -12px;
  text-transform: uppercase;
  background-color: #000;
  border-radius: 3px 3px 0 0;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: bold;
  padding: 5px 6px;
  margin-right: 5px;
}
conversation-quote div.message-quoted.bcc div.labels span.original {
  background-color: #9E9E9E;
}
conversation-quote div.message-quoted.bcc div.quote-information {
  margin: -15px 0 0 0;
}
conversation-quote div.message-quoted.bcc div.quote-information span.to {
  color: #111;
  font-weight: bold;
  margin-right: 10px;
}
conversation-quote div.message-quoted .quote-information {
  color: #757575;
}
conversation-quote div.message-quoted .quote-information div.attachment-counter, conversation-quote div.message-quoted .quote-information time {
  min-width: 40px;
}
conversation-quote div.message-quoted .quote-information md-icon {
  font-size: 16px;
  color: #757575;
  padding-top: 3px;
  margin-left: -5px;
}
conversation-quote div.message-quoted .quote-information span.sender {
  font-weight: bold;
}
conversation-quote div.message-quoted .quote-information time.moment-date {
  width: 100%;
  text-align: right;
  margin-right: 22px;
}
conversation-quote div.message-quoted .quote-information div.attachment-counter, conversation-quote div.message-quoted .quote-information time {
  min-width: 52px;
}
conversation-quote div.message-quoted .quote-information-recipients {
  color: #757575;
  margin-left: -4px;
  padding: 6px 0 0px 6px;
  /* IE11 & Edge's hack: overflow needs to be visible to be detected */
}
conversation-quote div.message-quoted .quote-information-recipients.expandable-recipients:hover, conversation-quote div.message-quoted .quote-information-recipients.expandable-recipients:focus {
  border-radius: 4px;
  background-color: #FAFAFA;
}
conversation-quote div.message-quoted .quote-information-recipients.non-expandable-recipients {
  cursor: text;
}
conversation-quote div.message-quoted .quote-information-recipients div.overflow-visible {
  overflow: visible !important;
}
conversation-quote div.message-quoted .quote-information-recipients span.to {
  margin-right: 10px;
}
conversation-quote div.message-quoted .quote-information-recipients md-icon.expand-icon {
  margin: 0 0 0 10px;
  font-size: 20px;
  font-weight: bolder;
  color: #000;
}
conversation-quote div.message-quoted .quote-information-recipients md-icon.hide-when-no-truncation {
  display: none;
}
conversation-quote div.message-quoted .conversation-message-body {
  display: block;
  padding: 6px 0;
  width: 100%;
}
conversation-quote div.message-quoted .conversation-message-body div.message-body {
  margin-bottom: 0;
}
conversation-quote div.message-quoted .conversation-attachments {
  width: 100%;
  margin: 16px 0px -16px;
  padding-top: 16px;
}
conversation-quote div.message-quoted.forwardedConversation:not(.selected) {
  opacity: 0.4;
}
conversation-quote div.message-quoted.forwardedConversation div.quote-information md-checkbox {
  margin-bottom: 0;
}
conversation-quote div.message-quoted.forwardedConversation.revoked div.quote-information md-checkbox {
  display: none;
}