conversation-message-body {
  display: block;
  padding: 0 16px 10px;
}
conversation-message-body .content-type-text-plain {
  margin-bottom: 2px;
}
conversation-message-body .message-body {
  width: 100%;
  font-family: inherit;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal !important;
}
conversation-message-body .message-body > * {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal !important;
}
conversation-message-body .message-body h1,
conversation-message-body .message-body h2,
conversation-message-body .message-body h3,
conversation-message-body .message-body h4,
conversation-message-body .message-body h5 {
  color: inherit;
  size: 1em;
  font-weight: 300;
  margin: 0 0 1em 0;
  line-height: 1.25;
}
conversation-message-body .message-body h1 {
  size: 1.35em;
}
conversation-message-body .message-body h2 {
  size: 1.3em;
}
conversation-message-body .message-body h3 {
  size: 1.25em;
}
conversation-message-body .message-body h4 {
  size: 1.2em;
}
conversation-message-body .message-body h5 {
  size: 1.1em;
}
conversation-message-body .message-body h6 {
  size: 1.1em;
  text-transform: uppercase;
}
conversation-message-body .message-body p,
conversation-message-body .message-body ul {
  margin: 4px 0;
}
conversation-message-body .message-body ul,
conversation-message-body .message-body ol {
  padding-left: 16px;
}
conversation-message-body .message-body img {
  max-width: 100%;
}
conversation-message-body .message-body table {
  border: 0;
}
conversation-message-body .message-body table tr,
conversation-message-body .message-body table td,
conversation-message-body .message-body table th {
  border: 0;
}
conversation-message-body .message-body span {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal !important;
}