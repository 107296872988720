.subscription h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}
.subscription .loader {
  display: flex;
  align-content: center;
  justify-content: center;
}
.subscription .actions {
  display: flex;
  align-content: end;
}
.subscription .success-image img {
  max-height: 150px;
  margin-bottom: 10px;
}