/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
.attach-file {
  transform: rotate(45deg);
}

.grey {
  color: #757575;
}

div.drafts div.zv-subheader aside .md-menu md-icon {
  color: #FFFFFF;
}
div.drafts div.zv-subheader aside .md-menu md-icon .grey {
  color: #757575;
}
div.drafts div.zv-subheader aside .title h2 {
  line-height: normal;
  text-align: inherit;
}
div.drafts div.zv-subheader aside .title .middle-list-header {
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.drafts div.zv-subheader aside .title .summary {
  color: #111;
  font-size: 15px;
  width: 100%;
  text-align: inherit;
}
div.drafts div.zv-subheader aside .title .summary .recipients {
  white-space: pre;
  float: left;
  width: calc(100% - 2em);
  overflow: hidden;
  text-overflow: ellipsis;
}
div.drafts div.zv-subheader aside .title .summary .recipient-icon {
  font-size: 18px !important;
  float: left;
}
div.drafts div.drafts-create {
  width: 100%;
  border-collapse: collapse;
}
div.drafts div.drafts-create md-card md-card-content {
  padding: 0;
}
div.drafts div.drafts-create section {
  padding: 0 16px;
}
div.drafts div.drafts-create section.to-recipients {
  padding-top: 16px;
}
div.drafts div.drafts-create section.to-recipients.disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
div.drafts div.drafts-create section.to-recipients.disabled > * {
  pointer-events: none;
}
div.drafts div.drafts-create section.subject md-input-container {
  margin-top: 16px;
  padding-bottom: 0;
}
div.drafts div.drafts-create section.subject md-input-container .md-errors-spacer {
  min-height: 0;
  margin-top: 0;
}
div.drafts div.drafts-create section.body {
  margin-top: 24px;
}
div.drafts div.drafts-create section.body md-input-container {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  width: 100%;
}
div.drafts div.drafts-create section.body md-input-container draft-body #pell .pell-content {
  min-height: 280px;
}
div.drafts div.drafts-create section.body md-input-container draft-body div.draft-text div.ql-editor, div.drafts div.drafts-create section.body md-input-container draft-body textarea {
  min-height: 280px;
}
@media (max-width: 959px) {
  div.drafts div.drafts-create section.actions .md-button {
    min-width: 42px;
  }
}
div.drafts div.drafts-create section.actions .editor-button {
  border-radius: 0;
}
div.drafts div.drafts-create section.actions .editor-button md-icon {
  width: 28px;
  height: 28px;
  font-size: 28px;
}
div.drafts div.drafts-create section.actions .editor-button .rich-text {
  background-color: rgba(31, 31, 31, 0.122);
  border-radius: 4px;
}
div.drafts div.drafts-create section.actions .time-left,
div.drafts div.drafts-create section.actions .md-button.md-primary:not([disabled]) md-icon {
  color: #0b74e7;
}
div.drafts div.drafts-create section.actions .expiration-button {
  border-color: transparent;
}
div.drafts div.drafts-create section.files {
  padding-bottom: 16px;
}
div.drafts div.drafts-create .forward-info {
  margin: 10px 24px;
}
div.drafts div.drafts-create .forward-info span.select-all {
  text-align: right;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
}

div.zivver-main.dragover div.drafts div.main-content md-card:not(.highlighted) {
  border: 3px dashed #757575;
}

md-dialog.message-create-dialog ul {
  list-style: none;
}
md-dialog.message-create-dialog md-input-container .email {
  margin-bottom: 0;
}
md-dialog.message-create-dialog md-input-container .credentials {
  margin-top: 0;
}
md-dialog.message-create-dialog section {
  padding-bottom: 0;
  padding-top: 0;
}

.md-chips .md-chip-input-container md-autocomplete input {
  width: 400px;
  flex-basis: auto;
}
@media (max-width: 959px) {
  .md-chips .md-chip-input-container md-autocomplete input {
    width: 95vw;
  }
}

md-menu-content.notification-settings small {
  width: 192px;
}

.quotes-notification {
  margin: 8px 18px;
}