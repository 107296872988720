/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

.flag {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('flags.png') no-repeat;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.flag {
		background: url('flags@2x.png') no-repeat;
		background-size: 384px 384px;
	}
}


.flag.flag-ad {
    background-position: -24px 0;
}

.flag.flag-ae {
    background-position: -48px 0;
}

.flag.flag-af {
    background-position: -72px 0;
}

.flag.flag-ag {
    background-position: -96px 0;
}

.flag.flag-ai {
    background-position: -120px 0;
}

.flag.flag-al {
    background-position: -144px 0;
}

.flag.flag-am {
    background-position: -168px 0;
}

.flag.flag-an {
    background-position: -192px 0;
}

.flag.flag-ao {
    background-position: -216px 0;
}

.flag.flag-ar {
    background-position: -240px 0;
}

.flag.flag-as {
    background-position: -264px 0;
}

.flag.flag-at {
    background-position: -288px 0;
}

.flag.flag-au {
    background-position: -312px 0;
}

.flag.flag-aw {
    background-position: -336px 0;
}

.flag.flag-ax {
    background-position: -360px 0;
}

.flag.flag-az {
    background-position: 0 -24px;
}

.flag.flag-ba {
    background-position: -24px -24px;
}

.flag.flag-bb {
    background-position: -48px -24px;
}

.flag.flag-bd {
    background-position: -72px -24px;
}

.flag.flag-be {
    background-position: -96px -24px;
}

.flag.flag-bf {
    background-position: -120px -24px;
}

.flag.flag-bg {
    background-position: -144px -24px;
}

.flag.flag-bh {
    background-position: -168px -24px;
}

.flag.flag-bi {
    background-position: -192px -24px;
}

.flag.flag-bj {
    background-position: -216px -24px;
}

.flag.flag-bl {
    background-position: -240px -24px;
}

.flag.flag-bm {
    background-position: -264px -24px;
}

.flag.flag-bn {
    background-position: -288px -24px;
}

.flag.flag-bo {
    background-position: -312px -24px;
}

.flag.flag-br {
    background-position: -336px -24px;
}

.flag.flag-bs {
    background-position: -360px -24px;
}

.flag.flag-bt {
    background-position: 0 -48px;
}

.flag.flag-bw {
    background-position: -24px -48px;
}

.flag.flag-by {
    background-position: -48px -48px;
}

.flag.flag-bz {
    background-position: -72px -48px;
}

.flag.flag-ca {
    background-position: -96px -48px;
}

.flag.flag-cd {
    background-position: -120px -48px;
}

.flag.flag-cf {
    background-position: -144px -48px;
}

.flag.flag-cg {
    background-position: -168px -48px;
}

.flag.flag-ch {
    background-position: -192px -48px;
}

.flag.flag-ci {
    background-position: -216px -48px;
}

.flag.flag-ck {
    background-position: -240px -48px;
}

.flag.flag-cl {
    background-position: -264px -48px;
}

.flag.flag-cm {
    background-position: -288px -48px;
}

.flag.flag-cn {
    background-position: -312px -48px;
}

.flag.flag-co {
    background-position: -336px -48px;
}

.flag.flag-cr {
    background-position: -360px -48px;
}

.flag.flag-cu {
    background-position: 0 -72px;
}

.flag.flag-cv {
    background-position: -24px -72px;
}

.flag.flag-cw {
    background-position: -48px -72px;
}

.flag.flag-cy {
    background-position: -72px -72px;
}

.flag.flag-cz {
    background-position: -96px -72px;
}

.flag.flag-de {
    background-position: -120px -72px;
}

.flag.flag-dj {
    background-position: -144px -72px;
}

.flag.flag-dk {
    background-position: -168px -72px;
}

.flag.flag-dm {
    background-position: -192px -72px;
}

.flag.flag-do {
    background-position: -216px -72px;
}

.flag.flag-dz {
    background-position: -240px -72px;
}

.flag.flag-ec {
    background-position: -264px -72px;
}

.flag.flag-ee {
    background-position: -288px -72px;
}

.flag.flag-eg {
    background-position: -312px -72px;
}

.flag.flag-eh {
    background-position: -336px -72px;
}

.flag.flag-er {
    background-position: -360px -72px;
}

.flag.flag-es {
    background-position: 0 -96px;
}

.flag.flag-et {
    background-position: -24px -96px;
}

.flag.flag-eu {
    background-position: -48px -96px;
}

.flag.flag-fi {
    background-position: -72px -96px;
}

.flag.flag-fj {
    background-position: -96px -96px;
}

.flag.flag-fk {
    background-position: -120px -96px;
}

.flag.flag-fm {
    background-position: -144px -96px;
}

.flag.flag-fo {
    background-position: -168px -96px;
}

.flag.flag-fr {
    background-position: -192px -96px;
}

.flag.flag-ga {
    background-position: -216px -96px;
}

.flag.flag-gb {
    background-position: -240px -96px;
}

.flag.flag-gd {
    background-position: -264px -96px;
}

.flag.flag-ge {
    background-position: -288px -96px;
}

.flag.flag-gg {
    background-position: -312px -96px;
}

.flag.flag-gh {
    background-position: -336px -96px;
}

.flag.flag-gi {
    background-position: -360px -96px;
}

.flag.flag-gl {
    background-position: 0 -120px;
}

.flag.flag-gm {
    background-position: -24px -120px;
}

.flag.flag-gn {
    background-position: -48px -120px;
}

.flag.flag-gq {
    background-position: -72px -120px;
}

.flag.flag-gr {
    background-position: -96px -120px;
}

.flag.flag-gs {
    background-position: -120px -120px;
}

.flag.flag-gt {
    background-position: -144px -120px;
}

.flag.flag-gu {
    background-position: -168px -120px;
}

.flag.flag-gw {
    background-position: -192px -120px;
}

.flag.flag-gy {
    background-position: -216px -120px;
}

.flag.flag-hk {
    background-position: -240px -120px;
}

.flag.flag-hn {
    background-position: -264px -120px;
}

.flag.flag-hr {
    background-position: -288px -120px;
}

.flag.flag-ht {
    background-position: -312px -120px;
}

.flag.flag-hu {
    background-position: -336px -120px;
}

.flag.flag-ic {
    background-position: -360px -120px;
}

.flag.flag-id {
    background-position: 0 -144px;
}

.flag.flag-ie {
    background-position: -24px -144px;
}

.flag.flag-il {
    background-position: -48px -144px;
}

.flag.flag-im {
    background-position: -72px -144px;
}

.flag.flag-in {
    background-position: -96px -144px;
}

.flag.flag-iq {
    background-position: -120px -144px;
}

.flag.flag-ir {
    background-position: -144px -144px;
}

.flag.flag-is {
    background-position: -168px -144px;
}

.flag.flag-it {
    background-position: -192px -144px;
}

.flag.flag-je {
    background-position: -216px -144px;
}

.flag.flag-jm {
    background-position: -240px -144px;
}

.flag.flag-jo {
    background-position: -264px -144px;
}

.flag.flag-jp {
    background-position: -288px -144px;
}

.flag.flag-ke {
    background-position: -312px -144px;
}

.flag.flag-kg {
    background-position: -336px -144px;
}

.flag.flag-kh {
    background-position: -360px -144px;
}

.flag.flag-ki {
    background-position: 0 -168px;
}

.flag.flag-km {
    background-position: -24px -168px;
}

.flag.flag-kn {
    background-position: -48px -168px;
}

.flag.flag-kp {
    background-position: -72px -168px;
}

.flag.flag-kr {
    background-position: -96px -168px;
}

.flag.flag-kw {
    background-position: -120px -168px;
}

.flag.flag-ky {
    background-position: -144px -168px;
}

.flag.flag-kz {
    background-position: -168px -168px;
}

.flag.flag-la {
    background-position: -192px -168px;
}

.flag.flag-lb {
    background-position: -216px -168px;
}

.flag.flag-lc {
    background-position: -240px -168px;
}

.flag.flag-li {
    background-position: -264px -168px;
}

.flag.flag-lk {
    background-position: -288px -168px;
}

.flag.flag-lr {
    background-position: -312px -168px;
}

.flag.flag-ls {
    background-position: -336px -168px;
}

.flag.flag-lt {
    background-position: -360px -168px;
}

.flag.flag-lu {
    background-position: 0 -192px;
}

.flag.flag-lv {
    background-position: -24px -192px;
}

.flag.flag-ly {
    background-position: -48px -192px;
}

.flag.flag-ma {
    background-position: -72px -192px;
}

.flag.flag-mc {
    background-position: -96px -192px;
}

.flag.flag-md {
    background-position: -120px -192px;
}

.flag.flag-me {
    background-position: -144px -192px;
}

.flag.flag-mf {
    background-position: -168px -192px;
}

.flag.flag-mg {
    background-position: -192px -192px;
}

.flag.flag-mh {
    background-position: -216px -192px;
}

.flag.flag-mk {
    background-position: -240px -192px;
}

.flag.flag-ml {
    background-position: -264px -192px;
}

.flag.flag-mm {
    background-position: -288px -192px;
}

.flag.flag-mn {
    background-position: -312px -192px;
}

.flag.flag-mo {
    background-position: -336px -192px;
}

.flag.flag-mp {
    background-position: -360px -192px;
}

.flag.flag-mq {
    background-position: 0 -216px;
}

.flag.flag-mr {
    background-position: -24px -216px;
}

.flag.flag-ms {
    background-position: -48px -216px;
}

.flag.flag-mt {
    background-position: -72px -216px;
}

.flag.flag-mu {
    background-position: -96px -216px;
}

.flag.flag-mv {
    background-position: -120px -216px;
}

.flag.flag-mw {
    background-position: -144px -216px;
}

.flag.flag-mx {
    background-position: -168px -216px;
}

.flag.flag-my {
    background-position: -192px -216px;
}

.flag.flag-mz {
    background-position: -216px -216px;
}

.flag.flag-na {
    background-position: -240px -216px;
}

.flag.flag-nc {
    background-position: -264px -216px;
}

.flag.flag-ne {
    background-position: -288px -216px;
}

.flag.flag-nf {
    background-position: -312px -216px;
}

.flag.flag-ng {
    background-position: -336px -216px;
}

.flag.flag-ni {
    background-position: -360px -216px;
}

.flag.flag-nl {
    background-position: 0 -240px;
}

.flag.flag-no {
    background-position: -24px -240px;
}

.flag.flag-np {
    background-position: -48px -240px;
}

.flag.flag-nr {
    background-position: -72px -240px;
}

.flag.flag-nu {
    background-position: -96px -240px;
}

.flag.flag-nz {
    background-position: -120px -240px;
}

.flag.flag-om {
    background-position: -144px -240px;
}

.flag.flag-pa {
    background-position: -168px -240px;
}

.flag.flag-pe {
    background-position: -192px -240px;
}

.flag.flag-pf {
    background-position: -216px -240px;
}

.flag.flag-pg {
    background-position: -240px -240px;
}

.flag.flag-ph {
    background-position: -264px -240px;
}

.flag.flag-pk {
    background-position: -288px -240px;
}

.flag.flag-pl {
    background-position: -312px -240px;
}

.flag.flag-pn {
    background-position: -336px -240px;
}

.flag.flag-pr {
    background-position: -360px -240px;
}

.flag.flag-ps {
    background-position: 0 -264px;
}

.flag.flag-pt {
    background-position: -24px -264px;
}

.flag.flag-pw {
    background-position: -48px -264px;
}

.flag.flag-py {
    background-position: -72px -264px;
}

.flag.flag-qa {
    background-position: -96px -264px;
}

.flag.flag-ro {
    background-position: -120px -264px;
}

.flag.flag-rs {
    background-position: -144px -264px;
}

.flag.flag-ru {
    background-position: -168px -264px;
}

.flag.flag-rw {
    background-position: -192px -264px;
}

.flag.flag-sa {
    background-position: -216px -264px;
}

.flag.flag-sb {
    background-position: -240px -264px;
}

.flag.flag-sc {
    background-position: -264px -264px;
}

.flag.flag-sd {
    background-position: -288px -264px;
}

.flag.flag-se {
    background-position: -312px -264px;
}

.flag.flag-sg {
    background-position: -336px -264px;
}

.flag.flag-sh {
    background-position: -360px -264px;
}

.flag.flag-si {
    background-position: 0 -288px;
}

.flag.flag-sk {
    background-position: -24px -288px;
}

.flag.flag-sl {
    background-position: -48px -288px;
}

.flag.flag-sm {
    background-position: -72px -288px;
}

.flag.flag-sn {
    background-position: -96px -288px;
}

.flag.flag-so {
    background-position: -120px -288px;
}

.flag.flag-sr {
    background-position: -144px -288px;
}

.flag.flag-ss {
    background-position: -168px -288px;
}

.flag.flag-st {
    background-position: -192px -288px;
}

.flag.flag-sv {
    background-position: -216px -288px;
}

.flag.flag-sy {
    background-position: -240px -288px;
}

.flag.flag-sz {
    background-position: -264px -288px;
}

.flag.flag-tc {
    background-position: -288px -288px;
}

.flag.flag-td {
    background-position: -312px -288px;
}

.flag.flag-tf {
    background-position: -336px -288px;
}

.flag.flag-tg {
    background-position: -360px -288px;
}

.flag.flag-th {
    background-position: 0 -312px;
}

.flag.flag-tj {
    background-position: -24px -312px;
}

.flag.flag-tk {
    background-position: -48px -312px;
}

.flag.flag-tl {
    background-position: -72px -312px;
}

.flag.flag-tm {
    background-position: -96px -312px;
}

.flag.flag-tn {
    background-position: -120px -312px;
}

.flag.flag-to {
    background-position: -144px -312px;
}

.flag.flag-tr {
    background-position: -168px -312px;
}

.flag.flag-tt {
    background-position: -192px -312px;
}

.flag.flag-tv {
    background-position: -216px -312px;
}

.flag.flag-tw {
    background-position: -240px -312px;
}

.flag.flag-tz {
    background-position: -264px -312px;
}

.flag.flag-ua {
    background-position: -288px -312px;
}

.flag.flag-ug {
    background-position: -312px -312px;
}

.flag.flag-us {
    background-position: -336px -312px;
}

.flag.flag-uy {
    background-position: -360px -312px;
}

.flag.flag-uz {
    background-position: 0 -336px;
}

.flag.flag-va {
    background-position: -24px -336px;
}

.flag.flag-vc {
    background-position: -48px -336px;
}

.flag.flag-ve {
    background-position: -72px -336px;
}

.flag.flag-vg {
    background-position: -96px -336px;
}

.flag.flag-vi {
    background-position: -120px -336px;
}

.flag.flag-vn {
    background-position: -144px -336px;
}

.flag.flag-vu {
    background-position: -168px -336px;
}

.flag.flag-wf {
    background-position: -192px -336px;
}

.flag.flag-ws {
    background-position: -216px -336px;
}

.flag.flag-ye {
    background-position: -240px -336px;
}

.flag.flag-yt {
    background-position: -264px -336px;
}

.flag.flag-za {
    background-position: -288px -336px;
}

.flag.flag-zm {
    background-position: -312px -336px;
}

.flag.flag-zw {
    background-position: -336px -336px;
}

.flag.flag-bq {
	background-position: -360px -336px;
}

.flag.flag-cc {
	background-position: 0px -360px;
}

.flag.flag-cx {
	background-position: -24px -360px;
}

.flag.flag-gf {
	background-position: -48px -360px;
}

.flag.flag-gp {
	background-position: -72px -360px;
}

.flag.flag-io {
	background-position: -96px -360px;
}

.flag.flag-pm {
	background-position: -120px -360px;
}

.flag.flag-re {
	background-position: -144px -360px;
}

.flag.flag-sj {
	background-position: -168px -360px;
}

.flag.flag-sx {
	background-position: -192px -360px;
}
