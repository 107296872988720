/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
callout-card md-card {
  border-radius: 0 !important;
  padding: 15px;
}
callout-card md-card.success {
  border-left: 5px solid #00BA9B;
}
callout-card md-card.info {
  border-left: 5px solid #0b74e7;
}
callout-card md-card.warning {
  border-left: 5px solid #FB743D;
}
callout-card md-card.error {
  border-left: 5px solid #E81B42;
}
callout-card md-card-content > div {
  display: inline-flex;
}
callout-card md-card-content span {
  margin-top: 5px;
  white-space: pre-line;
}
callout-card md-card-content .view-more-less-label {
  font-weight: bold;
  text-decoration: underline;
  padding-top: 1rem;
}
callout-card md-icon {
  margin-right: 10px;
}
callout-card md-icon.success {
  color: #00BA9B;
}
callout-card md-icon.info {
  color: #0b74e7;
}
callout-card md-icon.warning {
  color: #FB743D;
}
callout-card md-icon.error {
  color: #E81B42;
}