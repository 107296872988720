sidepane {
  /* New Colors */
  /* Black & white */
  /* Greys */
  /* Primary */
  /* Secondary */
  /* Critical */
  /* Warning */
  /* Success */
  /* Info */
  /* Texts */
  /* Borders */
  /* Neutral */
  /* Backgrounds */
  /* blue */
  /* green */
  /* orange */
  /* red */
  /* white */
  /* black */
  /* grey */
  /* purple */
  /* violation colors start */
  /* violation colors end */
  /* New Colors */
  /* Black & white */
  /* Greys */
  /* Primary */
  /* Secondary */
  /* Critical */
  /* Warning */
  /* Success */
  /* Info */
  /* Texts */
  /* Borders */
  /* Neutral */
  /* Backgrounds */
  /* blue */
  /* green */
  /* orange */
  /* red */
  /* white */
  /* black */
  /* grey */
  /* purple */
  /* violation colors start */
  /* violation colors end */
  /**
  	These breakpoints are defined in Angular Marerial.

  	Our css is set up modular and does not need to know variables
  	from earlier included stylesheets.

  	Including the AM stylesheet everywhere will result in its CSS
  	every time it was included. So we add the CSS AM breakpoints here
  	so we can import this file to get the the breakpoint variables
  	wherever we need them.
   */
  /**
  	This is a special (hihi) color scheme that should not be used outside the sidenav
  	(and app-switcher, but that is inside the sidenav).

  	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
  	also has access to these variables.
   */
  /**
   * This include can be used to share common used variables
   */
}
sidepane md-sidenav {
  background-color: #FAFAFA;
  width: 480px;
}
@media (max-width: 599px) {
  sidepane md-sidenav {
    top: 0;
    width: 100vw;
    max-width: 100vw;
  }
}
sidepane md-sidenav md-toolbar {
  height: 64px;
  max-height: 64px;
  color: #111;
  background-color: #fff;
}
sidepane md-sidenav md-content {
  background-color: #FAFAFA;
}
sidepane md-sidenav md-content h2.rightpane-component-heading {
  color: #111;
  font-size: 16px;
  font-weight: normal;
  padding: 0;
  outline: none;
}
sidepane md-sidenav md-content h2.rightpane-component-heading.unclickable {
  cursor: default;
}
sidepane md-sidenav md-content h2.rightpane-component-heading md-icon {
  margin-right: 0.5em;
  width: 0.9em;
  height: 0.9em;
  font-size: 22px;
}
sidepane md-sidenav md-content h2.rightpane-component-heading .collapsed-icon {
  margin-top: 3px;
  float: right;
  display: inline-block;
}
sidepane md-sidenav md-content md-list {
  background-color: #FFFFFF;
}