/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
show-password {
  position: absolute;
  padding-top: 3px;
}
show-password .show-password {
  margin-left: -35px;
  user-select: none;
}
show-password .show-password .ng-hide.ng-hide-animate {
  display: none !important;
}
show-password _:-ms-lang(x), show-password .show-password {
  display: none;
}