specials-attach-button {
  width: auto;
}
specials-attach-button button.browse {
  margin-left: 45%;
}
specials-attach-button input {
  opacity: 0;
  width: 1px;
  margin-left: -5px;
}