/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
body.auth main div.reset-password {
  margin: 8px;
}
body.auth main div.reset-password h2 {
  margin-bottom: 0;
}
body.auth main div.reset-password > span {
  line-height: 1.5em;
}
body.auth main div.reset-password form password-component {
  margin-left: 2px;
}
body.auth main div.reset-password form password-component md-input-container {
  margin-right: 10px !important;
}
body.auth main div.reset-password form show-password {
  margin-top: -22px;
}
body.auth main div.reset-password form div.recovery {
  margin-top: 18px;
}
body.auth main div.reset-password form div.recovery md-checkbox {
  margin: 0 8px 0 0;
}
body.auth main div.reset-password form a:link, body.auth main div.reset-password form a:visited {
  text-decoration: none;
}
body.auth main div.reset-password form .for-explanation {
  text-align: center !important;
  margin: 8px 0 40px 0;
}