/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
md-dialog.warning-dialog {
  width: 450px;
}
md-dialog.warning-dialog md-toolbar h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
md-dialog.warning-dialog .warning-icon {
  color: #FB743D;
}
md-dialog.warning-dialog md-dialog-content {
  margin-top: 1em;
  border-bottom: 1px solid #EEEEEE;
}
md-dialog.warning-dialog md-dialog-content .bigger-icon {
  font-size: 48px;
  height: 48px;
  min-height: 48px;
  min-width: 48px;
}
md-dialog.warning-dialog md-dialog-content .explanation-wrapper {
  margin-left: 16px;
  margin-top: 12px;
}
md-dialog.warning-dialog md-dialog-content .explanation-wrapper p {
  margin: 0;
}
md-dialog.warning-dialog md-dialog-actions {
  padding: 8px 16px 8px 16px;
  border-top-style: none !important;
}
md-dialog.warning-dialog md-dialog-actions.reverse {
  flex-direction: row-reverse;
}