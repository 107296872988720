/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
participants-list {
  display: block;
}
participants-list .expanded-container {
  margin: 8px;
}
participants-list .expanded-container .expanded {
  display: flex;
  flex-direction: column;
}
participants-list .expanded-container .expanded .description {
  margin: 8px 16px 16px;
}
participants-list .expanded-container .expanded .verification-input {
  margin: 8px 8px 16px;
  display: flex;
  align-items: center;
}
participants-list .expanded-container .expanded .verification-input md-input-container {
  width: 100%;
}
@media (max-width: 599px) {
  participants-list .expanded-container .expanded .verification-input md-input-container {
    max-width: 80%;
  }
}
participants-list .expanded-container .expanded .verification-input .copy-to-clipboard {
  height: 30px;
}
participants-list .expanded-container .expanded .verification-input .copy-to-clipboard-tooltip {
  padding-top: 2px;
  flex-direction: unset;
}
participants-list .expanded-container .expanded .verification-input .edit-verification-method {
  padding: 0;
  margin: 0;
  width: 25px;
}
participants-list .expanded-container .expanded .verification-input .edit-verification-method.md-button {
  padding-left: 0;
}
participants-list .expanded-container .expanded .verification-input .show-password {
  display: block !important;
  margin-left: -25px;
}
participants-list .expanded-container .expanded .verification-input .md-icon-button {
  padding-right: 0;
  margin-right: 0;
}
participants-list .expanded-container .expanded .md-warn {
  align-self: flex-end;
}
participants-list > div {
  padding-bottom: 0 !important;
  /*&.unclickable {
  	cursor: default;

  	&:hover {
  		background: inherit;
  	}
  }*/
}
participants-list > div md-list {
  padding: 0;
  overflow-x: hidden;
  margin-bottom: 8px;
}
participants-list > div md-list md-list-item {
  background: #FFFFFF;
  transition: opacity 250ms ease-in-out;
  display: flex;
  flex-direction: row;
  align-content: start;
  justify-content: center;
  border-bottom: 0 !important;
}
participants-list > div md-list md-list-item.unclickable {
  cursor: default;
}
participants-list > div md-list md-list-item.unclickable .md-button:not([disabled]):hover {
  background-color: transparent;
}
participants-list > div md-list md-list-item.disabled-card button {
  background-color: #FFFFFF !important;
  cursor: default;
}
participants-list > div md-list md-list-item.disabled-card .md-list-item-inner {
  opacity: 0.5;
  cursor: default;
}
participants-list > div md-list md-list-item.disabled-card .md-ripple-container {
  display: none;
  cursor: default;
}
participants-list > div md-list md-list-item.ng-enter {
  opacity: 0;
}
participants-list > div md-list md-list-item.ng-enter.ng-enter-active {
  opacity: 1;
}
participants-list > div md-list md-list-item.ng-leave {
  display: none;
  opacity: 0;
}
participants-list > div md-list md-list-item.ng-leave.ng-leave-active {
  opacity: 0;
}
participants-list > div md-list md-list-item .md-list-item-inner.no-button {
  min-height: inherit;
  overflow: hidden;
  width: 95%;
}
participants-list > div md-list md-list-item .md-list-item-inner .md-list-item-text {
  width: 235px;
  max-width: 235px;
}
participants-list > div md-list md-list-item .md-list-item-inner .md-list-item-text p.md-body-1 {
  font-weight: 400;
  display: block;
}
participants-list > div md-list md-list-item .md-list-item-inner .md-list-item-text p.md-body-1 md-icon {
  font-size: 18px;
  line-height: inherit;
  margin-right: -5px;
}
participants-list > div md-list md-list-item .md-list-item-inner .md-list-item-text p.md-body-1.verified {
  color: #111;
}
participants-list > div md-list md-list-item .md-list-item-inner .md-list-item-text p.md-body-1.verified md-icon {
  color: #000;
}
participants-list > div md-list md-list-item .md-list-item-inner .md-list-item-text p.md-body-1.unverified, participants-list > div md-list md-list-item .md-list-item-inner .md-list-item-text p.md-body-1.inaccessible {
  color: #FB743D;
}
participants-list > div md-list md-list-item .md-list-item-inner .md-list-item-text p.md-body-1.unverified md-icon, participants-list > div md-list md-list-item .md-list-item-inner .md-list-item-text p.md-body-1.inaccessible md-icon {
  color: #FB743D;
}
participants-list > div .suspended, participants-list > div .removed {
  top: -6px;
  width: 100%;
  position: absolute;
  height: 100%;
  margin-left: -30px;
}
participants-list > div .suspended span, participants-list > div .removed span {
  font-size: 12px;
  color: #FB743D;
  display: block;
  text-align: right;
}
participants-list > div .toggle {
  display: inline-block;
  float: right;
}
participants-list > div .expanded-container {
  margin: 8px;
}
participants-list > div .expanded-container .expanded {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  transition: opacity 250ms ease-in-out;
  border-bottom: 0 !important;
  min-height: 100px;
}
participants-list > div .expanded-container .expanded.small {
  min-height: 55px;
}
participants-list > div .expanded-container .expanded .first {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  padding-left: 16px;
  padding-right: 10px;
  margin: 16px 0;
  width: 100%;
}
participants-list > div .expanded-container .expanded .first > div {
  line-height: 24px;
  margin-left: 10px;
}
participants-list > div .expanded-container .expanded .first md-icon {
  margin-top: 2px;
}
participants-list > div .expanded-container .expanded .description {
  margin: 8px 16px 16px;
}
participants-list > div .expanded-container .expanded .md-warn {
  align-self: flex-end;
}
participants-list > div .expanded-container .expanded md-icon {
  font-size: 20px;
}
participants-list > div .expanded-container .expanded .inaccessible {
  color: #1F1F1F;
  background-color: #FFF7ED;
  border-radius: 4px;
  white-space: normal;
  padding: 4px 8px;
  margin: 8px 0;
}
participants-list > div .expanded-container .expanded md-input-container.no-error-spacer .md-errors-spacer {
  display: none;
}
participants-list > div .expanded-container .expanded md-input-container.bar-number {
  margin: 0 16px 16px;
}
participants-list > div .expanded-container .expanded .md-button {
  position: relative;
  float: right;
}
participants-list > div hr {
  width: 90%;
  border: 0;
  height: 1px;
  background-color: #E0E0E0;
}
participants-list > div img.bcc-icon {
  margin-right: 0.5em;
  width: 24px;
  vertical-align: bottom;
}