/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
.format-alignment-container {
  background-color: #FFFFFF;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(31, 31, 31, 0.14), 0 3px 14px 2px rgba(31, 31, 31, 0.12), 0 5px 5px -3px rgba(31, 31, 31, 0.2);
}
.format-alignment-container .md-button:not([disabled]).md-icon-button {
  margin: 0;
  padding: 0;
  min-width: 0;
  min-height: 0;
  height: 30px;
  width: 30px;
  border-radius: 4px;
}
.format-alignment-container .md-button:not([disabled]).md-icon-button md-icon {
  color: #6f7175;
}
.format-alignment-container .md-button:not([disabled]).md-icon-button:hover {
  background-color: #f5f5f5;
}
.format-alignment-container .md-button:not([disabled]).md-icon-button:hover md-icon {
  color: #000;
}