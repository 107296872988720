/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
.format-colors-container {
  background-color: #FFFFFF;
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(31, 31, 31, 0.14), 0 3px 14px 2px rgba(31, 31, 31, 0.12), 0 5px 5px -3px rgba(31, 31, 31, 0.2);
  padding: 16px;
}
.format-colors-container .color-table {
  margin: 8px;
}
.format-colors-container .color-table .title {
  margin: 8px 0;
  font-weight: bold;
}
.format-colors-container .color-table table td .color-box {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}
.format-colors-container .color-table table td .color-box md-icon {
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
}
.format-colors-container .color-table table td .color-box .material-icons {
  font-size: 16px;
  font-weight: 900;
}
.format-colors-container .color-table table td:hover {
  outline: 1px solid #1F1F1F;
}