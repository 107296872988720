/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
md-dialog.dialog-wizard {
  display: block;
  max-width: 450px;
  width: 450px;
}
md-dialog.dialog-wizard md-toolbar, md-dialog.dialog-wizard .md-toolbar-tools {
  height: 56px !important;
  max-height: 56px !important;
  min-height: 56px !important;
}
md-dialog.dialog-wizard.md-dialog-fullscreen {
  overflow: hidden;
  height: 100%;
}
md-dialog.dialog-wizard.md-dialog-fullscreen md-dialog-content .placeholder-main img {
  width: auto;
}
md-dialog.dialog-wizard.md-dialog-fullscreen md-dialog-actions {
  border-top: none;
}
md-dialog.dialog-wizard md-dialog-content {
  display: block;
  width: auto;
  height: 100%;
  max-height: calc(100% - 60px - 84px);
  padding: 0;
}
@media (max-width: 599px) {
  md-dialog.dialog-wizard md-dialog-content md-content {
    padding: 8px;
  }
}
md-dialog.dialog-wizard md-dialog-content h1 {
  font-size: 20px;
  font-weight: 500;
}
md-dialog.dialog-wizard md-dialog-content .placeholder-main {
  text-align: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
}
md-dialog.dialog-wizard md-dialog-content .placeholder-main img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}
md-dialog.dialog-wizard md-tab-content > div {
  height: 100%;
}
md-dialog.dialog-wizard md-content {
  height: 100%;
  background-color: transparent;
}
md-dialog.dialog-wizard md-tabs-content-wrapper {
  top: 0;
}
md-dialog.dialog-wizard md-tabs-wrapper, md-dialog.dialog-wizard md-tabs-canvas {
  display: none;
}
md-dialog.dialog-wizard md-tab-content {
  overflow: hidden;
}
@-moz-document url-prefix() {
  md-dialog.dialog-wizard md-tab-content {
    transform: none;
    transition: none;
    animation: none;
  }
}
@media all and (-ms-high-contrast: none) {
  md-dialog.dialog-wizard md-tab-content {
    transform: none;
    transition: none;
    animation: none;
  }
}
md-dialog.dialog-wizard .wizard-dots-container {
  display: block;
  width: 100%;
  height: auto;
  padding-bottom: 8px;
  text-align: center;
}
md-dialog.dialog-wizard .wizard-dots-container md-icon {
  font-size: 16px;
  height: 16px;
  min-height: 16px;
  width: 24px;
  min-width: 24px;
}
md-dialog.dialog-wizard .wizard-dots-container .wizard-dots md-button, md-dialog.dialog-wizard .wizard-dots-container .wizard-dots button {
  padding: 0;
  margin: 0;
  min-width: 0;
}
md-dialog.dialog-wizard .wizard-dots-container .wizard-dots md-button.md-focused, md-dialog.dialog-wizard .wizard-dots-container .wizard-dots md-button:hover, md-dialog.dialog-wizard .wizard-dots-container .wizard-dots button.md-focused, md-dialog.dialog-wizard .wizard-dots-container .wizard-dots button:hover {
  background: transparent !important;
}
md-dialog.dialog-wizard .wizard-dots-container .wizard-dots md-button md-icon.current, md-dialog.dialog-wizard .wizard-dots-container .wizard-dots button md-icon.current {
  color: #000;
}
md-dialog.dialog-wizard .wizard-dots-container .wizard-dots md-button md-icon.faded, md-dialog.dialog-wizard .wizard-dots-container .wizard-dots button md-icon.faded {
  color: #c0c4cb;
}
md-dialog.dialog-wizard md-dialog-actions button:nth-child(2):not(:last-child) {
  display: none;
}