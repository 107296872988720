/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
.main-view .recover-messages .header {
  text-align: center;
}
.main-view .recover-messages .header h2 {
  margin-bottom: 0;
}
.main-view .recover-messages .recovery-form p, .main-view .recover-messages .recovery-form span {
  text-align: left;
}
.main-view .recover-messages .recovery-form .recovery-container {
  margin-top: 28px;
}
.main-view .recover-messages .recovery-form .recovery-container md-input-container {
  padding: 0;
}
.main-view .recover-messages md-input-container {
  margin-top: 18px;
  margin-left: 4px !important;
  margin-bottom: 0 !important;
}
.main-view .recover-messages md-input-container label {
  margin-bottom: 4px;
}
.main-view .recover-messages md-input-container.recovery-code-container {
  margin-top: 0 !important;
  margin-left: 0 !important;
}
.main-view .recover-messages > span, .main-view .recover-messages .details {
  line-height: 1.8em;
}
.main-view .recover-messages > span span.label, .main-view .recover-messages .details span.label {
  padding: 4px;
  background-color: #FFF7ED;
  border-radius: 5px;
}
.main-view .recover-messages p.recover-explanation {
  margin-bottom: 4px;
}
.main-view .recover-messages a:link, .main-view .recover-messages a:visited {
  text-decoration: none;
}
.main-view .recover-messages a:link.learn-more p, .main-view .recover-messages a:visited.learn-more p {
  color: #000;
  margin: 0 0 30px;
  text-decoration: underline;
}
.main-view .recover-messages .for-explanation {
  text-align: center !important;
  margin: 8px 0 40px 0;
}