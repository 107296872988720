/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
conversation-reply {
  display: block;
}
conversation-reply .md-button {
  text-transform: initial;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  padding: 0 12px 0 8px;
}
conversation-reply .md-button md-icon {
  font-size: 22px;
  margin-right: 10px;
}