/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
specials-input-image {
  margin: 5px 0;
}
specials-input-image > div:focus {
  outline: 0;
}
specials-input-image > div:focus > .specials-input-image-container {
  outline: 0;
  background: #757575;
}
specials-input-image > div:focus > .specials-input-image-container img {
  opacity: 0.5;
}
specials-input-image .hidden-validator {
  margin: 0;
  padding: 0;
  top: 0 !important;
  width: 100%;
}
specials-input-image .hidden-validator input {
  height: 1px;
}
specials-input-image .specials-input-image-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: #EEEEEE;
  overflow: hidden;
}
specials-input-image .specials-input-image-container md-progress-linear {
  z-index: 1;
  position: absolute;
  top: 0;
}
specials-input-image .specials-input-image-container h2 {
  z-index: 1;
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: #FAFAFA;
}
specials-input-image .specials-input-image-container .upload-complete,
specials-input-image .specials-input-image-container .upload-pending {
  transition: all 0.5s linear;
}
specials-input-image .specials-input-image-container .upload-pending {
  top: 0;
}
specials-input-image .specials-input-image-container .upload-pending.ng-hide-add {
  top: -10px;
}
specials-input-image .specials-input-image-container .upload-pending.ng-hide-add.ng-hide-add-active {
  top: 0;
}
specials-input-image .specials-input-image-container .upload-complete {
  top: 0;
}
specials-input-image .specials-input-image-container .upload-complete.ng-hide {
  top: -10px;
}
specials-input-image .specials-input-image-container .upload-complete.ng-hide-add.ng-hide-add-active {
  top: -10px;
}
specials-input-image .specials-input-image-container img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
specials-input-image .specials-input-image-container md-button {
  z-index: 1;
}