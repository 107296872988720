/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
draft-violation-item {
  display: block;
}
draft-violation-item .violation {
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0;
  margin: 4px;
}
draft-violation-item .violation md-icon {
  margin: 0 16px;
}
draft-violation-item .violation.violation-suggestion {
  background-color: #F3F6FC;
  color: #095cb5;
}
draft-violation-item .violation.violation-error {
  background-color: #FFF3F4;
  color: #E81B42;
}
draft-violation-item .violation.violation-suggestion-icon md-icon {
  color: #0b74e7;
}
draft-violation-item .violation.violation-error-icon md-icon {
  color: #E81B42;
}
draft-violation-item .violation .violation-item-text {
  word-break: break-word;
}
draft-violation-item .violation .violation-item-text .details {
  color: #757575;
}