/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
conversation-message {
  display: block;
}
@media all and (-ms-high-contrast: none) {
  conversation-message .hasExpirationBar {
    margin-top: 40px;
  }
}
conversation-message .deleted {
  color: rgba(31, 31, 31, 0.38) !important;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}
conversation-message md-card-avatar {
  margin-top: 8px;
}
conversation-message time.moment-date {
  font-size: 12px;
  color: #757575;
  text-align: right;
  padding-right: 16px;
}
conversation-message md-card:focus {
  outline: none;
}
conversation-message md-card.own.revoked {
  background-color: #EEEEEE;
}
conversation-message md-card.own.revoked md-card-content {
  color: #9E9E9E;
}
conversation-message md-card.own.revoked .revoked-notice {
  font-style: italic;
  font-size: 0.8em;
  color: #1F1F1F;
  text-align: center;
  margin-bottom: 8px;
}
conversation-message md-card.new md-card-header md-card-avatar:before {
  content: " ";
  display: block;
  position: absolute;
  background-color: #FF3600;
  width: 3px;
  height: 100%;
  top: 0px;
  left: 0px;
}
conversation-message md-card.selected {
  border: 2px solid #000;
}
conversation-message md-card div.header-container {
  max-width: 100%;
}
conversation-message md-card div.header-container md-card-header {
  width: 100%;
  padding-right: 2px;
}
conversation-message md-card div.header-container md-card-header:last-child md-card-avatar {
  margin-left: 0;
}
conversation-message md-card div.header-container md-card-header md-card-header-text {
  overflow: hidden;
  max-height: none !important;
  display: inline-block;
}
conversation-message md-card div.header-container md-card-header md-card-header-text span.md-title {
  height: 48px;
}
conversation-message md-card div.header-container md-card-header md-card-header-text span.md-title div.sender-info {
  padding-top: 8px;
}
conversation-message md-card div.header-container md-card-header md-card-header-text span.md-title div.sender-info span.sender {
  display: inline-block;
}
conversation-message md-card div.header-container md-card-header md-card-header-text span.md-title div.sender-info span.sender a, conversation-message md-card div.header-container md-card-header md-card-header-text span.md-title div.sender-info span.sender span.sent-by {
  color: #000;
  font-weight: 400;
}
conversation-message md-card div.header-container md-card-header:last-child .actions-wrapper {
  margin-right: 16px;
}
conversation-message md-card div.header-container md-card-header .actions-wrapper {
  height: 40px;
}
conversation-message md-card div.menu-actions {
  margin: 0 1px;
}
conversation-message md-card div.menu-actions .md-icon-button {
  margin-left: 0 !important;
  margin-right: 0 !important;
  border: 1px solid #e7eaee;
}
conversation-message md-card div.menu-actions .md-icon-button > md-icon {
  color: #111;
}
conversation-message md-card div.menu-actions .offset-bottom-of > md-icon {
  position: relative;
  bottom: 2px;
}
conversation-message md-card div.menu-actions .slightly-offset-bottom-of > md-icon {
  position: relative;
  bottom: 1px;
}
conversation-message md-card div.menu-actions.hide-actions {
  visibility: hidden;
}
conversation-message md-card md-card-content {
  width: 100%;
  padding: 0;
}
conversation-message md-card md-card-content.text-plain.no-counters {
  padding-bottom: 12px;
}
conversation-message md-card md-card-content.text-html {
  margin-bottom: 0px;
}
conversation-message md-card md-card-content.text-html.no-counters {
  padding-bottom: 6px;
}
conversation-message md-card md-card-content.text-html.no-counters .previews {
  margin-bottom: -10px;
  min-height: 20px;
}
conversation-message md-card md-card-content.text-html.no-counters .noPreviews {
  margin-top: 20px;
  margin-bottom: -20px;
}
conversation-message md-card md-card-content.message-revoked div {
  font-style: italic;
  color: #757575;
}
conversation-message md-card md-card-content md-list {
  margin-bottom: 3px;
}
conversation-message md-card md-card-content div.counters {
  text-align: left;
  padding-left: 4px;
  padding-bottom: 4px;
}
conversation-message md-card .revoked-notice span.link a {
  text-decoration: none;
}
conversation-message md-card .bcc-info {
  display: block;
  padding: 10px 16px;
  color: #757575;
}
conversation-message md-card .bcc-info span.link a {
  text-decoration: none;
}
conversation-message .downloadMessage {
  display: none;
}
conversation-message h2.rightpane-component-heading {
  color: #111;
  font-size: 16px;
  font-weight: normal;
  padding: 0;
  outline: none;
}
conversation-message h2.rightpane-component-heading.unclickable {
  cursor: default;
}
conversation-message h2.rightpane-component-heading md-icon {
  margin-right: 0.5em;
  width: 0.9em;
  height: 0.9em;
  font-size: 22px;
}
conversation-message h2.rightpane-component-heading .collapsed-icon {
  margin-top: 3px;
  float: right;
}
@media (max-width: 599px) {
  conversation-message md-card {
    height: 100%;
  }
}

body.conversations md-menu-content.message-info {
  max-height: 344px;
}