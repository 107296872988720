/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
html body.landing {
  background-color: #000;
}
html body.landing support-menu {
  bottom: 30px;
}
html body.landing #overlay {
  background-color: rgba(3, 29, 64, 0.8);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}
@media (min-width: 601px) {
  html body.landing #overlay {
    display: none;
  }
}
html body.landing div.main-view {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
html body.landing div.main-view div.beta {
  position: absolute;
  bottom: 50px;
  left: 36px;
  padding: 16px;
  background-color: #FFFFFF;
  z-index: 3;
  text-align: center;
  opacity: 1;
  width: auto;
  height: 241px;
  max-width: 505px;
  border-radius: 8px;
}
html body.landing div.main-view div.beta .md-button {
  width: 190px;
  box-shadow: none !important;
}
html body.landing div.main-view div.beta .beta-close {
  position: absolute;
  right: 7px;
  top: 13px;
}
html body.landing div.main-view div.beta .beta-close md-icon {
  font-size: 16px;
  color: #1F1F1F;
  font-weight: 700;
}
html body.landing div.main-view div.beta .beta-image {
  width: 192px;
  display: inline-block;
  float: left;
  height: 209px;
  background-color: #F3F6FC;
  border-radius: 8px;
}
html body.landing div.main-view div.beta .beta-image img {
  margin-top: 24px;
}
@media (max-width: 599px) {
  html body.landing div.main-view div.beta .beta-image {
    width: 100%;
    height: 151px;
    margin-top: 22px;
  }
  html body.landing div.main-view div.beta .beta-image img {
    margin-top: 4px;
    height: 140px;
  }
}
html body.landing div.main-view div.beta .beta-text {
  width: 281px;
  height: 209px;
  padding: 32px 8px;
  display: inline-block;
}
html body.landing div.main-view div.beta .beta-text div {
  margin-bottom: 24px;
}
html body.landing div.main-view div.beta .beta-text div.title {
  font-size: 16px;
  font-weight: 500;
}
html body.landing div.main-view div.beta .beta-text div.desc {
  font-size: 12px;
  line-height: 16px;
}
@media (max-width: 599px) {
  html body.landing div.main-view div.beta .beta-text {
    margin-top: 10px;
    width: 100%;
    height: 129px;
    padding: 8px;
  }
  html body.landing div.main-view div.beta .beta-text div {
    margin-bottom: 16px;
  }
}
@media (max-width: 599px) {
  html body.landing div.main-view div.beta {
    left: 24px;
    right: 24px;
    bottom: 24px;
    height: 348px;
  }
}
html body.landing div.main-view header h1.logo {
  margin: 48px auto 80px;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  background: transparent url("../../../static/img/logo_white.svg") center center no-repeat;
  background-size: contain;
  display: block;
  width: 220px;
  height: 77px;
}
html body.landing div.main-view language-select {
  position: absolute;
  top: 0;
  right: 0;
}
html body.landing div.main-view language-select md-icon, html body.landing div.main-view language-select md-select .md-select-value * {
  color: #FFFFFF;
}
html body.landing div.main-view footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(31, 31, 31, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  color: #FFFFFF;
  padding: 8px 16px;
}
html body.landing div.main-view footer version {
  padding: 0;
  font-size: 10px;
}
html body.landing div.main-view footer div.secured-by {
  height: 44px;
}
html body.landing div.main-view footer div.secured-by span {
  display: block;
}
html body.landing div.main-view footer div.secured-by span.text {
  line-height: 44px;
  margin-right: 4px;
}
html body.landing div.main-view footer div.secured-by span.logo {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  width: 125px;
  height: 44px;
  background-size: 125px 44px;
  background-image: url("../auth/logo_white.svg");
}

div.landing main p.intro {
  text-align: left;
}
div.landing main main form {
  margin: 2em;
}
div.landing main main form .logo {
  margin-bottom: 2em;
}
div.landing main main form .logo img {
  height: 125px;
  width: 250px;
}
div.landing main main form h2 {
  margin-bottom: 5px;
}
div.landing main main form h3 {
  text-align: left;
}
div.landing main main form label {
  margin-bottom: 5px;
}
div.landing main main form md-input-container {
  padding: 0px;
  margin-bottom: 0px;
}
div.landing main main form md-input-container input {
  width: 100%;
}
div.landing main main form button {
  width: 150px;
  margin: 8px;
  margin-top: 16px;
}
div.landing main main form .zivver-login {
  margin-top: 10px;
  font-size: 13px;
}

div.landing.open {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}
div.landing.open main {
  width: 368px;
  overflow-y: auto;
  padding: 1.5em 1em;
  min-height: 400px;
}
div.landing.open main img.logo {
  display: block;
  max-height: 96px;
  max-width: 300px;
  margin: 0 auto 1em auto;
}
div.landing.open main conversation-challenge {
  margin-top: 1em;
}

div.landing.download-attachment div.card {
  display: block;
  width: 500px;
  max-width: 95%;
  background-color: #FFFFFF;
  border-radius: 4px;
  margin: 0 auto;
  padding: 1em 8em;
}
@media (max-width: 599px) {
  div.landing.download-attachment div.card {
    padding: 1em 20%;
  }
}
div.landing.download-attachment div.card.error {
  text-align: left;
  padding: 24px 92px 32px;
}
@media (max-width: 599px) {
  div.landing.download-attachment div.card.error {
    padding: 24px 16px 32px;
  }
}
div.landing.download-attachment div.card md-progress-circular {
  margin: 0 auto;
}
div.landing.download-attachment div.card span.file-name {
  display: block;
  padding-top: 12px;
}
div.landing.download-attachment div.card span.file-size {
  display: block;
}
div.landing.download-attachment div.card p.link > * {
  text-decoration: none;
}
div.landing.download-attachment div.card md-icon {
  float: left;
  margin-left: -6px;
  width: 56px;
  height: 56px;
}
div.landing.download-attachment div.card .md-button {
  margin-left: 0;
}