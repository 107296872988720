/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
@media screen and (min-width: 0\0 ) {
  .copy-to-clipboard-tooltip {
    margin-top: 7px;
  }
}
.copy-to-clipboard-tooltip .copy-icon {
  align-self: flex-end;
}

.copy-tooltip {
  text-align: center !important;
  width: 100px !important;
}