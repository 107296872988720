/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
conversation-event {
  display: block;
  margin: 8px;
}
conversation-event .event-container {
  display: flex;
  align-items: center;
  min-height: 40px;
  border-radius: 4px;
  padding: 8px;
  font-size: 0.95em;
  border: 1px solid;
  color: #1F1F1F;
}
conversation-event .event-container::after {
  content: "";
  display: block;
  clear: both;
}
conversation-event .event-container .text {
  font-style: italic;
  padding-left: 0.5em;
  margin-left: 8px;
  width: calc(100% - 50px);
  flex: 1;
}
conversation-event .event-container.success {
  background-color: #E7FAF7;
  border-color: #00BA9B;
}
conversation-event .event-container.success md-icon {
  color: #00BA9B;
}
conversation-event .event-container.success md-button {
  color: #00BA9B;
}
conversation-event .event-container.alert {
  background-color: #FFF7ED;
  border-color: #FB743D;
}
conversation-event .event-container.alert md-icon {
  color: #FB743D;
}
conversation-event .event-container.alert md-button {
  color: #FB743D;
}
conversation-event .event-container.warning {
  background-color: #FFF3F4;
  border-color: #E81B42;
}
conversation-event .event-container.warning md-icon {
  color: #E81B42;
}
conversation-event .event-container.warning md-button {
  color: #E81B42;
}
conversation-event .event-container.error {
  background-color: #FFF3F4;
  border-color: #E81B42;
}
conversation-event .event-container.error md-icon {
  color: #E81B42;
}
conversation-event .event-container.error md-button {
  color: #E81B42;
}
conversation-event .event-container.notification {
  background-color: #f3f6fc;
  border-color: #0b74e7;
}
conversation-event .event-container.notification md-icon {
  color: #0b74e7;
}
conversation-event .event-container.notification md-button {
  color: #0b74e7;
}
conversation-event .md-button {
  margin: -8px 0;
}
conversation-event .md-button {
  margin: -8px 0;
}