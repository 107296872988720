/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
specials-input-filename-pattern input {
  display: none;
}
specials-input-filename-pattern span.filename {
  line-height: 48px;
  font-size: 14px;
  text-indent: 10px;
}
specials-input-filename-pattern div[ng-messages] {
  font-size: 13px;
  color: #FB743D;
}