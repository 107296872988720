/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
.recipient-dialog {
  width: 480px;
  max-width: 100%;
}
.recipient-dialog md-dialog-actions {
  display: flex;
  justify-content: space-between;
  padding: 0 16px 16px;
}
.recipient-dialog md-dialog-actions .md-button {
  margin: 8px;
}
.recipient-dialog div.violations draft-violation-item {
  margin-bottom: 4px;
}
.recipient-dialog .avatar-section {
  width: 85px;
  float: left;
}
@media (max-width: 600px) {
  .recipient-dialog .avatar-section {
    width: 100%;
    text-align: center;
  }
}
.recipient-dialog .details-section {
  width: calc(100% - 85px);
  float: left;
  padding-right: 16px;
}
@media (max-width: 600px) {
  .recipient-dialog .details-section {
    width: 100%;
    padding-right: 0;
  }
}
.recipient-dialog .details-section md-input-container {
  display: inline-block;
  width: 100%;
  margin-bottom: 12px;
  margin-top: 12px;
}
.recipient-dialog .details-section md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  padding-bottom: 3px;
  padding-left: 8px;
}
.recipient-dialog .details-section md-input-container .md-input, .recipient-dialog .details-section md-input-container md-select {
  background-color: #FAFAFA;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  color: #111;
  padding: 8px;
}
.recipient-dialog .details-section md-input-container .md-input.ng-invalid.ng-dirty, .recipient-dialog .details-section md-input-container md-select.ng-invalid.ng-dirty {
  border-width: 1px;
  border-color: #E81B42;
  padding: 8px;
}
.recipient-dialog .details-section md-input-container md-select {
  padding: 0 0 0 4px !important;
}
.recipient-dialog .details-section md-input-container md-select .md-select-value {
  border-bottom-width: 0 !important;
}
.recipient-dialog .details-section md-input-container.md-input-focused .md-input {
  border-width: 1px;
  border-color: #000;
  padding: 8px;
}
.recipient-dialog .details-section md-input-container.md-input-focused .md-input.ng-invalid.ng-dirty {
  border-color: #E81B42;
}
.recipient-dialog .details-section md-input-container input, .recipient-dialog .details-section md-input-container md-select {
  width: 100%;
}
.recipient-dialog .details-section md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  padding-bottom: 3px;
  padding-left: 8px;
}
.recipient-dialog .details-section md-input-container .md-input, .recipient-dialog .details-section md-input-container md-select {
  background-color: #FAFAFA;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  color: #111;
  padding: 8px;
}
.recipient-dialog .details-section md-input-container .md-input.ng-invalid.ng-dirty, .recipient-dialog .details-section md-input-container md-select.ng-invalid.ng-dirty {
  border-width: 1px;
  border-color: #E81B42;
  padding: 8px;
}
.recipient-dialog .details-section md-input-container md-select {
  padding: 0 0 0 4px !important;
}
.recipient-dialog .details-section md-input-container md-select .md-select-value {
  border-bottom-width: 0 !important;
}
.recipient-dialog .details-section md-input-container.md-input-focused .md-input {
  border-width: 1px;
  border-color: #000;
  padding: 8px;
}
.recipient-dialog .details-section md-input-container.md-input-focused .md-input.ng-invalid.ng-dirty {
  border-color: #E81B42;
}
.recipient-dialog .details-section md-input-container.full-name .md-errors-spacer {
  min-height: 0;
}
.recipient-dialog .details-section input-phonenumber .phone-number-container {
  margin: 12px 2px 20px 2px;
}
.recipient-dialog .details-section #zivverVerification {
  background-color: #FFFFFF;
  border: none;
  max-height: 30px;
  padding: 0 0 0 4px;
}
.recipient-dialog .md-errors-spacer {
  min-height: 0;
}
.recipient-dialog .md-dialog-actions {
  margin-top: 24px;
}

.verification-desc {
  display: none;
}

/**
	md-select, when clicked on, renders in a separate container div.
	the specific container for this dialog has an extra class called verifications
 */
.md-select-menu-container.verifications md-select-menu {
  max-height: initial;
}
.md-select-menu-container.verifications md-select-menu md-content {
  max-height: initial;
}
.md-select-menu-container.verifications md-select-menu md-option {
  max-width: 350px;
}
.md-select-menu-container.verifications md-select-menu md-option div.md-text {
  white-space: normal;
}
.md-select-menu-container.verifications.md-active .verification-name {
  font-weight: 700;
  line-height: 25px;
}
.md-select-menu-container.verifications.md-active .verification-desc {
  display: inline;
}
.md-select-menu-container.verifications.md-active .select-option {
  height: 60px;
}