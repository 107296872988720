/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
html body.auth {
  background-color: #f5f5f5;
}
html body.auth div.main-view language-select {
  position: absolute;
  top: 0;
  right: 0;
}
html body.auth div.main-view main {
  display: block;
  width: 500px;
  max-width: 95%;
  background-color: #FFFFFF;
  border-radius: 4px;
  margin: 0 auto;
  padding: 1em;
}
html body.auth div.main-view main h2 {
  text-align: center;
  margin-top: 0;
}
@media (max-width: 599px) {
  html body.auth div.main-view main h2 {
    font-size: 20px;
  }
}
html body.auth div.main-view main h3 {
  margin-top: 0;
  padding: 0;
}
html body.auth div.main-view main h3, html body.auth div.main-view main p, html body.auth div.main-view main span {
  color: #1F1F1F;
  text-align: center;
}
html body.auth div.main-view main p.intro {
  margin-bottom: 2em;
}
html body.auth div.main-view main .link, html body.auth div.main-view main .link:visited {
  color: #000;
}
html body.auth div.main-view main.new-challenge {
  width: 640px;
  padding: 32px 72px;
}
html body.auth div.main-view main.new-challenge h2, html body.auth div.main-view main.new-challenge p, html body.auth div.main-view main.new-challenge span {
  text-align: left;
}
@media (max-width: 959px) {
  html body.auth div.main-view main.new-challenge {
    position: relative;
    top: 12px;
    min-height: 50px;
    max-height: 78vh;
    width: 98%;
    padding: 12px;
  }
}
html body.auth div.main-view main md-input-container label {
  margin-bottom: 4px;
  transform: unset !important;
  font-size: 12px;
  color: #757575;
}
html body.auth div.main-view main md-input-container input {
  border: solid #E0E0E0;
  border-width: 1px !important;
  border-radius: 2px;
  background-color: #FAFAFA;
  padding: 0px 4px;
  font-size: 14px;
}
html body.auth div.main-view main md-input-container input.ng-invalid.ng-dirty {
  border: 1px solid #E81B42;
}
html body.auth div.main-view main md-input-container input.ng-dirty.ng-empty {
  border: 1px solid #E0E0E0;
}
html body.auth div.main-view main md-input-container.md-input-invalid input {
  border-color: #E81B42;
  border-width: 1px !important;
  background-color: #FFF3F4;
}
html body.auth div.main-view main div.input-with-submit {
  display: flex;
  align-items: flex-start;
}
html body.auth div.main-view main div.input-with-submit md-input-container {
  width: 74%;
}
html body.auth div.main-view main div.input-with-submit .md-button {
  display: inline-block;
  margin-left: 1em;
  margin-top: 17px;
}
html body.auth div.main-view main div.input-with-submit md-icon {
  top: 5px;
}
@media (max-width: 599px) {
  html body.auth div.main-view main div.input-with-submit {
    flex-direction: column;
    flex-wrap: wrap;
  }
  html body.auth div.main-view main div.input-with-submit md-input-container {
    width: 100%;
    margin-bottom: 0;
  }
  html body.auth div.main-view main div.input-with-submit .md-button {
    align-self: flex-end;
    margin-top: 0;
  }
}

div.auth {
  width: 100%;
  height: 100%;
  padding-bottom: 2em;
}
div.auth header h1 {
  margin: 2em 0 1em 0;
}
div.auth header h1 a {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  background: transparent url("./logo_default.svg") center center no-repeat;
  background-size: contain;
  display: block;
  width: 220px;
  height: 77px;
  margin: 0 auto;
}
@media (max-width: 599px) {
  div.auth header h1 {
    margin: 1em 0 0.5em 0;
  }
  div.auth header h1 a {
    width: 180px;
    height: 63px;
  }
}
@media (min-height: 600px) {
  div.auth header h1 {
    margin: 2em 0 2em 0;
  }
}
div.auth main form > md-input-container {
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
}
div.auth main form > md-input-container.input-icon-label input {
  width: calc(100% - 12px);
}
@media (max-width: 599px) {
  div.auth main form > md-input-container {
    margin: 12px 0;
  }
}
div.auth main form button.md-button {
  margin-left: 0;
}
div.auth main form small {
  color: #757575;
}
div.auth main div.warning {
  margin: 5px 10px 5px 4px;
  padding: 1em;
  border: 1px solid #E81B42;
  border-radius: 4px;
  background: #FFF3F4;
  font-size: 0.9em;
  max-height: 0;
  opacity: 0;
  transition: all 125ms linear;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
div.auth main div.warning.warning-show {
  opacity: 1;
  max-height: 150px;
}
div.auth main div.warning md-icon {
  display: inline-block;
  width: 10%;
  min-width: 36px;
  color: #E81B42;
}
div.auth main div.warning translate {
  display: inline-block;
  width: 90%;
  text-align: left;
  padding-top: 4px;
}
div.auth main div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding: 0 2px 0 4px;
}
div.auth main div.actions.centered {
  justify-content: center;
}
div.auth main div.secondary-links {
  position: relative;
  top: 25px;
  height: 0;
  overflow-y: visible;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
div.auth main div.secondary-links a[ng-click] {
  text-decoration: underline;
}
@media (max-width: 599px) {
  div.auth main div.secondary-links {
    display: block;
  }
  div.auth main div.secondary-links span {
    display: inline-block;
    width: 100%;
    margin-bottom: 1em;
  }
  div.auth main div.secondary-links span:last-child {
    margin-bottom: 0;
  }
}
div.auth main div.loader {
  width: 100%;
  padding-top: 10px;
}
div.auth main div.error {
  width: 100%;
  margin-right: 10px;
}
div.auth footer version {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 0;
  padding-left: 5px;
}
@media (max-width: 959px) {
  div.auth footer version {
    display: none;
  }
}