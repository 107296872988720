/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
.format-link-container {
  width: 400px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 4px;
  box-shadow: 0 8px 10px 1px rgba(31, 31, 31, 0.14), 0 3px 14px 2px rgba(31, 31, 31, 0.12), 0 5px 5px -3px rgba(31, 31, 31, 0.2);
}
.format-link-container md-dialog-content {
  width: 100%;
  padding-left: 16px;
}
.format-link-container md-dialog-content .label {
  color: #757575;
}
.format-link-container md-dialog-content md-input-container {
  width: 100%;
  margin: 0;
  padding: 0;
}
.format-link-container md-dialog-content md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  padding-bottom: 3px;
  padding-left: 8px;
}
.format-link-container md-dialog-content md-input-container .md-input {
  border: 1px solid #e7eaee;
  border-radius: 3px;
  color: #111;
  padding: 8px;
}
.format-link-container md-dialog-content md-input-container .md-input.ng-invalid.ng-dirty {
  border-width: 1px;
  border-color: #E81B42;
  padding: 8px;
}
.format-link-container md-dialog-content md-input-container .md-input:focus {
  border: 1px solid #000;
}
.format-link-container md-dialog-content md-input-container .md-errors-spacer {
  min-height: 0;
}
.format-link-container md-dialog-actions {
  width: 100%;
  padding-right: 16px;
}