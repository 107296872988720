/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
@media screen and (min-width: 0\0 ) {
  .copy-to-clipboard {
    margin-top: 7px;
  }
}
.copy-to-clipboard .copy--icon {
  margin: auto;
}
.copy-to-clipboard .copy--confirmation {
  text-align: right;
  font-size: 10px;
  color: #757575;
  width: 100px;
  position: absolute;
  margin-top: 35px;
  right: 15px;
}