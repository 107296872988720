/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
recipient-chip .recipient-chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
recipient-chip md-icon {
  font-size: 20px !important;
  outline: none;
}
recipient-chip md-icon.unknown {
  color: #1F1F1F;
}
recipient-chip md-icon.verified {
  color: #0b74e7;
}
recipient-chip md-icon.warning {
  color: #FB743D;
}
recipient-chip md-icon.error {
  color: #FFFFFF;
}
recipient-chip md-icon.validation-icon {
  min-width: 16px;
  min-height: 19px;
  height: 19px;
  width: 16px;
  margin-left: 4px;
}
recipient-chip div.md-contact-name {
  height: 32px;
  margin: 0 12px;
  font-size: 16px;
  color: #1F1F1F;
}