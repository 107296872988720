/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
#link-tooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  max-width: 100%;
  height: 40px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(31, 31, 31, 0.2), 0 1px 1px 0 rgba(31, 31, 31, 0.14), 0 2px 1px -1px rgba(31, 31, 31, 0.12);
}
#link-tooltip > div {
  padding: 0 8px;
}
#link-tooltip > div.edit {
  border-left: 1px solid #757575;
  border-right: 1px solid #757575;
}
#link-tooltip > div a {
  text-decoration: none;
}