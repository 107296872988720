/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
draft-validations-bar > div {
  display: flex;
  border-radius: 4px 4px 0 0;
}
draft-validations-bar > div md-card-header-text {
  height: 45px;
  overflow: hidden;
}
draft-validations-bar > div .left-icon {
  margin-top: 5px;
}
draft-validations-bar > div.error {
  background-color: rgba(232, 27, 66, 0.2);
}
draft-validations-bar > div.unknown {
  background-color: rgba(158, 158, 158, 0.2);
}
draft-validations-bar > div.verified {
  background-color: rgba(11, 116, 231, 0.2);
}
draft-validations-bar > div > md-icon {
  margin-right: 12px;
}
draft-validations-bar > div div {
  width: 100%;
  padding: 8px;
  font-size: 13px;
}
draft-validations-bar > div .violation-description {
  display: inline-block;
  height: 30px !important;
  width: calc(100% - 25px) !important;
  margin-left: 20px;
  position: relative;
  line-height: 22px;
  top: -33px;
}
draft-validations-bar > div:focus, draft-validations-bar > div:active {
  outline: none;
}
draft-validations-bar md-icon.unknown, draft-validations-bar .md-button.unknown {
  color: #9E9E9E;
}
draft-validations-bar md-icon.verified, draft-validations-bar .md-button.verified {
  color: #0b74e7;
}
draft-validations-bar md-icon.info, draft-validations-bar .md-button.info {
  color: #0b74e7;
}
draft-validations-bar md-icon.warning, draft-validations-bar .md-button.warning {
  color: #FB743D;
}
draft-validations-bar md-icon.error, draft-validations-bar .md-button.error {
  color: #E81B42;
}
draft-validations-bar md-icon {
  font-size: 20px !important;
  outline: none;
}
draft-validations-bar .md-button:hover:not([disabled]) {
  background-color: transparent !important;
}
draft-validations-bar .desc {
  cursor: text;
}