/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
.conversation-info .subject-container {
  padding-bottom: 0;
}
.conversation-info .subject-container .subject-header {
  padding: 0 8px;
  color: #111;
  font-size: 16px;
  font-weight: normal;
}
.conversation-info .subject-container .subject-header md-icon {
  margin-right: 0.5em;
  width: 0.9em;
  height: 0.9em;
  font-size: 22px;
}
.conversation-info .subject-container .subject-content {
  padding: 0 8px;
  margin: 0;
  display: block;
  color: #111;
  line-height: 20px;
}
.conversation-info participants-list md-list {
  max-height: 324px;
  overflow-y: auto;
}
.conversation-info .btn-add-container {
  margin-top: 0;
}
.conversation-info .btn-add-container md-card {
  margin-top: 0;
}
.conversation-info .btn-add-container .btn-add.md-button {
  width: 100%;
  margin: 0;
  padding: 9px 16px;
}
.conversation-info .btn-add-container .btn-add.md-button > md-icon {
  margin: 8px 16px 8px 8px;
}
.conversation-info .add {
  margin: 8px 16px;
  background-color: #FFFFFF;
}
.conversation-info .add draft-recipients {
  padding: 8px;
}
.conversation-info .add .loader {
  padding: 16px;
}
.conversation-info .add .warnings-container {
  border-radius: 4px;
  white-space: normal;
  padding: 8px;
  margin: 8px auto;
  width: 95%;
}
.conversation-info .add .warnings-container.unknown {
  color: #1F1F1F;
  background-color: rgba(158, 158, 158, 0.2);
}
.conversation-info .add .warnings-container.verified {
  color: #095cb5;
  background-color: rgba(11, 116, 231, 0.2);
}
.conversation-info .add .warnings-container.warning {
  color: #FB743D;
  background-color: rgba(251, 116, 61, 0.2);
}
.conversation-info .add .warnings-container.error {
  color: #E81B42;
  background-color: rgba(232, 27, 66, 0.2);
}
.conversation-info .add .warnings-container > md-icon {
  margin: 5px 10px 5px 0px;
}
.conversation-info .add .warnings-container > md-icon.unknown {
  color: #9E9E9E;
}
.conversation-info .add .warnings-container > md-icon.verified {
  color: #0b74e7;
}
.conversation-info .add .warnings-container > md-icon.info {
  color: #0b74e7;
}
.conversation-info .add .warnings-container > md-icon.warning {
  color: #FB743D;
}
.conversation-info .add .warnings-container > md-icon.error {
  color: #E81B42;
}
.conversation-info input {
  width: 270px !important;
}
.conversation-info input:read-only {
  width: 100% !important;
  margin-right: -25px;
}