/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
mfa-backup-codes .intro {
  text-align: left;
  padding: 1em;
}
mfa-backup-codes .code-list {
  column-count: 2;
  overflow: auto;
}
mfa-backup-codes .code-list small {
  display: inline-block;
  width: 30px;
  text-align: right;
}