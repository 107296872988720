/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
conversation-attachments {
  display: block;
  padding: 10px;
}
conversation-attachments.no-message {
  border-top: none;
}
conversation-attachments div.previews, conversation-attachments div.noPreviews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
conversation-attachments div.attachment {
  width: 245px;
  margin-right: 1em;
  margin-bottom: 1em;
}
conversation-attachments div.attachment a:focus {
  outline: none;
}
conversation-attachments div.attachment md-icon {
  color: #6f7175;
}
conversation-attachments div.attachment md-icon:hover {
  color: #000;
}
conversation-attachments div.attachment a.download, conversation-attachments div.attachment a.info {
  display: inline-block;
  float: left;
  background-color: #f5f5f5;
  border: 1px solid #e7eaee;
  height: 36px;
}
conversation-attachments div.attachment a.download md-icon, conversation-attachments div.attachment a.info md-icon {
  height: 36px;
  max-height: 36px;
  line-height: 36px;
}
conversation-attachments div.attachment a.download:hover md-icon, conversation-attachments div.attachment a.info:hover md-icon {
  color: #000;
  cursor: pointer;
}
conversation-attachments div.attachment a.download {
  width: 209px;
  border-right: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  padding-left: 4px;
  color: #757575;
}
conversation-attachments div.attachment a.download md-icon {
  float: right;
  padding-top: 1px;
}
conversation-attachments div.attachment a.download span.truncate {
  text-align: left;
  width: 177px;
  line-height: 36px;
}
conversation-attachments div.attachment a.info {
  width: 36px;
  border-left: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  text-align: center;
}
conversation-attachments div.attachment.selected a.info md-icon {
  color: #6f7175;
}
conversation-attachments div.previews a.info, conversation-attachments div.previews a.download {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
conversation-attachments div.previews .preview {
  border: 1px solid rgba(158, 158, 158, 0.2);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 0;
  text-align: right;
  padding-right: 4px;
  height: 150px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
conversation-attachments div.previews .preview:hover md-icon {
  color: #000;
}
conversation-attachments div.previews .preview:focus {
  outline: none;
}
conversation-attachments div.download-all {
  padding-bottom: 16px;
  padding-top: 10px;
}
conversation-attachments div.download-all span.show-more {
  color: #111;
  cursor: pointer;
}
conversation-attachments div.download-all span.show-more > md-icon {
  color: #000;
  transition: transform 150ms ease-in-out;
}
conversation-attachments div.download-all span.show-more > md-icon.collapse {
  transform: rotate(360deg);
}
conversation-attachments div.download-all span.show-more > md-icon.expand {
  transform: rotate(180deg);
}
conversation-attachments div.download-all span.show-more:hover {
  text-decoration: underline;
}
conversation-attachments div.download-all a {
  color: #111;
  cursor: pointer;
  text-decoration: none;
  margin-left: 32px;
}
conversation-attachments div.download-all a > md-icon {
  color: #000;
  font-size: 20px;
}
conversation-attachments div.download-all a:hover {
  text-decoration: underline;
}

img.preload {
  z-index: 0;
  position: absolute;
  left: -1000px;
  top: -1000px;
  width: 1px;
  height: 1px;
}