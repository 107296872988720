/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
md-dialog.labels-dialog {
  width: 360px;
  max-width: 100%;
}
md-dialog.labels-dialog div.search {
  padding: 9px 14px 0 14px;
}
md-dialog.labels-dialog md-input-container {
  width: 100%;
  padding: 0;
}
md-dialog.labels-dialog md-input-container label:not(.md-no-float):not(.md-container-ignore) {
  padding-bottom: 5px;
  padding-left: 8px;
}
md-dialog.labels-dialog md-input-container .md-input, md-dialog.labels-dialog md-input-container md-select {
  background-color: #FAFAFA;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  color: #757575;
  padding: 8px;
}
md-dialog.labels-dialog md-input-container .md-input.ng-invalid.ng-dirty, md-dialog.labels-dialog md-input-container md-select.ng-invalid.ng-dirty {
  border-width: 1px;
  border-color: #E81B42;
  padding: 8px;
}
md-dialog.labels-dialog md-input-container md-select {
  padding: 0 0 0 4px !important;
  height: 30px;
}
md-dialog.labels-dialog md-input-container md-select .md-select-value {
  border-bottom-width: 0 !important;
  min-height: 28px;
  padding: 0;
}
md-dialog.labels-dialog md-input-container md-select[disabled] .md-select-value {
  margin: 0;
  padding-bottom: 1px;
}
md-dialog.labels-dialog md-input-container.md-input-focused .md-input {
  border-width: 1px;
  border-color: #000;
  padding: 8px;
}
md-dialog.labels-dialog md-input-container.md-input-focused .md-input.ng-invalid.ng-dirty {
  border-color: #E81B42;
}
md-dialog.labels-dialog ul.labels {
  list-style: none;
  padding: 0;
}
md-dialog.labels-dialog ul.labels li {
  padding: 0 14px;
}
md-dialog.labels-dialog ul.labels li md-checkbox {
  max-width: 75%;
}
md-dialog.labels-dialog ul.labels li md-checkbox div.md-label {
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: inherit;
  overflow: hidden;
}
md-dialog.labels-dialog ul.labels li span.label-actions {
  display: inline-block;
  float: right;
}
md-dialog.labels-dialog ul.labels li span.label-actions md-icon.label-color {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}
md-dialog.labels-dialog ul.labels li span.label-actions md-icon.label-edit, md-dialog.labels-dialog ul.labels li span.label-actions md-icon.label-delete {
  color: #E0E0E0;
}
md-dialog.labels-dialog ul.labels li span.label-actions md-icon.label-edit:active, md-dialog.labels-dialog ul.labels li span.label-actions md-icon.label-edit:focus, md-dialog.labels-dialog ul.labels li span.label-actions md-icon.label-delete:active, md-dialog.labels-dialog ul.labels li span.label-actions md-icon.label-delete:focus {
  outline: none;
}
md-dialog.labels-dialog div.colors span md-icon {
  font-size: 12px;
  line-height: 24px;
  float: right;
  text-align: center;
}
md-dialog.labels-dialog div.colors span.active {
  border-bottom: 1px solid #E0E0E0;
}
md-dialog.labels-dialog div.colors span.active md-icon {
  font-size: 18px;
}
md-dialog.labels-dialog div.colors span:focus {
  outline: none;
}
md-dialog.labels-dialog md-dialog-actions {
  padding: 0 8px;
}
md-dialog.labels-dialog md-dialog-actions button {
  margin-left: 0;
}