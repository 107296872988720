/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
specials-input-radio-button {
  display: block;
  margin-top: 15px;
}

md-radio-group.md-focused.ng-pristine md-radio-button:first-child .md-container:before {
  background-color: rgba(31, 31, 31, 0.15);
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
}