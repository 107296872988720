/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
draft-attachment-chip md-icon {
  font-size: 20px !important;
  line-height: 27px !important;
  width: 24px !important;
  left: 5px !important;
}
draft-attachment-chip md-icon.violation-unknown {
  color: #9E9E9E;
}
draft-attachment-chip md-icon.violation-okay {
  color: #0B74E7;
}
draft-attachment-chip md-icon.violation-warning {
  color: #FB743D;
}
draft-attachment-chip md-icon.violation-error {
  color: #FFFFFF;
}
draft-attachment-chip div.md-contact-name {
  margin-left: 4px;
}
draft-attachment-chip md-progress-linear .md-bar,
draft-attachment-chip md-progress-linear.md-default-theme .md-bar {
  background-color: #757575 !important;
}
draft-attachment-chip md-progress-linear.md-default-theme[md-mode=buffer].md-primary .md-dashed:before,
draft-attachment-chip md-progress-linear[md-mode=buffer].md-primary .md-dashed:before {
  background: radial-gradient(#FAFAFA 0, #FAFAFA 16%, transparent 42%);
}