/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/* New Colors */
/* Black & white */
/* Greys */
/* Primary */
/* Secondary */
/* Critical */
/* Warning */
/* Success */
/* Info */
/* Texts */
/* Borders */
/* Neutral */
/* Backgrounds */
/* blue */
/* green */
/* orange */
/* red */
/* white */
/* black */
/* grey */
/* purple */
/* violation colors start */
/* violation colors end */
/**
	These breakpoints are defined in Angular Marerial.

	Our css is set up modular and does not need to know variables
	from earlier included stylesheets.

	Including the AM stylesheet everywhere will result in its CSS
	every time it was included. So we add the CSS AM breakpoints here
	so we can import this file to get the the breakpoint variables
	wherever we need them.
 */
/**
	This is a special (hihi) color scheme that should not be used outside the sidenav
	(and app-switcher, but that is inside the sidenav).

	so the colors are *outside* of the `sidenav {` block, to make sure the `app-switcher`
	also has access to these variables.
 */
/**
 * This include can be used to share common used variables
 */
password-component {
  display: inline-block;
  width: 100%;
}
password-component div.input-with-icon {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
password-component div.input-with-icon md-icon {
  margin: 22px 0 0 0;
}
password-component div.input-with-icon md-input-container {
  width: calc(100% - 32px) !important;
  margin-left: 8px !important;
}
password-component md-input-container {
  width: 100%;
}
password-component md-input-container.md-input-invalid input {
  border-color: #FB743D;
  border-width: 1px !important;
  background-color: #FFF7ED !important;
}
password-component md-input-container.reset {
  margin-left: 0px;
}
password-component md-input-container.score-0 label {
  color: #0b74e7 !important;
}
password-component md-input-container.score-0 input {
  border-color: #0b74e7 !important;
  background-color: #f3f6fc !important;
}
password-component md-input-container.score-1 label {
  color: #FB743D !important;
}
password-component md-input-container.score-1 input {
  border-color: #FB743D !important;
  background-color: #FFF7ED !important;
}
password-component md-input-container.score-2 label {
  color: #FB743D !important;
}
password-component md-input-container.score-2 input {
  border-color: #FB743D !important;
  background-color: #FFF7ED !important;
}
password-component md-input-container.score-3 label, password-component md-input-container.score-4 label {
  color: #00BA9B !important;
}
password-component md-input-container.score-3 input, password-component md-input-container.score-4 input {
  border-color: #00BA9B !important;
  background-color: #E7FAF7 !important;
}
password-component md-input-container.warning-overwrite label {
  color: #FB743D !important;
}
password-component md-input-container.warning-overwrite input {
  border-color: #FB743D !important;
  background-color: #FFF7ED !important;
}
password-component md-input-container.pristine label {
  color: #6f7175 !important;
}
password-component md-input-container.pristine input {
  border-color: #e7eaee !important;
  background-color: #f5f5f5 !important;
}
password-component md-input-container.match label {
  color: #00BA9B !important;
}
password-component md-input-container.match input {
  border-color: #00BA9B !important;
  background-color: #E7FAF7 !important;
}
password-component md-input-container input {
  border: solid #E0E0E0;
  border-width: 1px !important;
  border-radius: 2px !important;
  background-color: #FAFAFA !important;
  padding: 0px 4px;
  font-size: 14px;
}
password-component md-input-container input.ng-invalid.ng-dirty {
  border: 1px solid #FB743D;
}
password-component md-input-container input.ng-dirty.ng-empty {
  border: 1px solid #E0E0E0;
  background-color: #FAFAFA !important;
}